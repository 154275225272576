<template>
  <div class="right_card_box">
    <div
      class="right_card"
      v-for="item in list"
      :key="item.img"
      v-loading="loading"
    >
      <img class="card_img" :src="item.img" />
      <div class="card_bottom" :class="{ blank_theme_bom: isBlank }">
        <div class="text_box">
          <p class="top_text">{{ item.name }}</p>
          <p class="bottom_text">{{ item.tag[0] }}</p>
        </div>
      </div>
      <div class="logo_box">
        <img
          class="logo"
          src="http://jianzhan.dev.xinzhidi.cn/upload/20211014/3462a57643d0e6f02b0f83126bc14dc5.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { get_template_list } from "@/api/tWeb.js";

export default {
  data() {
    return {
      list: [],
      loading: false,
      isBlank: false,
    };
  },
  methods: {
    get_list() {
      this.loading = true;
      let params = {};
      get_template_list(params)
        .then((res) => {
          this.list = res.data.list;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.get_list();
    let theme = JSON.parse(window.localStorage.getItem("theme"));
    if (theme) {
      if (theme === "blankTheme") {
        this.isBlank = true;
        return;
      }
      if (theme === "whiteTheme") {
        this.isBlank = false;
        return;
      }
    } else {
      this.isBlank = false;
      return;
    }
  },
};
</script>

<style lang="less" scoped>
// 特效
.right_card {
  transition: all 0.5s;
}
.right_card:hover {
  transform: scale(1.02);
}
.right_card:hover .logo {
  animation: logoRotate 0.8s linear infinite;
}
@keyframes logoRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.blank_theme_bom {
  background: url("http://jianzhan.dev.xinzhidi.cn/upload/20211013/870e039035bd9eca1bc7e115c6b1e729.png")
    no-repeat !important;
}

@media screen and (max-width: 1920px) {
  .right_card_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .right_card {
      width: 231px;
      height: 176px;
      border-radius: 14px;
      margin-bottom: 21px;
      margin-right: 21px;
      position: relative;
      cursor: pointer;
      .card_img {
        width: 100%;
        height: 100%;
        border-radius: 14px;
        object-fit: cover;
      }
      .card_bottom {
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 232px;
        height: 77px;
        // background: linear-gradient(180deg, #9c6aff 0%, #615bff 100%);
        background: url("http://jianzhan.dev.xinzhidi.cn/upload/20211012/33b3ee8c35f2c50973023606e3ddeebe.png")
          no-repeat;
        background-size: 100% !important;
        border-radius: 14px;

        // clip-path: polygon(-50% 70%, 102% -5%, 100% 100%, -30% 100%, 0% 0%);

        .text_box {
          margin-left: 50px;
          height: 100%;
          text-align: center;
          .top_text {
            height: 17px;
            font-size: 14px;
            margin-top: 29px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 17px;
          }
          .bottom_text {
            height: 14px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 14px;
            margin-top: 4px;
          }
        }
      }

      .logo_box {
        width: 42px;
        height: 42px;
        box-shadow: 0px 9px 8px -5px rgba(0, 0, 0, 0.2);
        border-radius: 14px;
        background-color: #fff;
        position: absolute;
        left: 20px;
        bottom: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        .logo {
          transition: all 0.5s;
          width: 29px;
          height: 28px;
        }
      }
    }
    .right_card:nth-child(4n) {
      margin-right: 0;
    }
  }
}
@media screen and (min-width: 1920px) {
  .right_card_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .right_card {
      width: 352px;
      height: 261px;
      border-radius: 14px;
      margin-bottom: 21px;
      margin-right: 21px;
      position: relative;
      cursor: pointer;
      .card_img {
        width: 100%;
        height: 100%;
        border-radius: 14px;
        object-fit: cover;
      }
      .card_bottom {
        position: absolute;
        left: -1px;
        bottom: 0;
        width: 353px;
        height: 113px;
        // background: linear-gradient(180deg, #9c6aff 0%, #615bff 100%);
        background: url("http://jianzhan.dev.xinzhidi.cn/upload/20211012/33b3ee8c35f2c50973023606e3ddeebe.png")
          no-repeat;
        background-size: 100% !important;
        border-radius: 14px;
        // clip-path: polygon(-50% 70%, 100% -4%, 100% 100%, -30% 100%);

        .text_box {
          margin-left: 60px;
          height: 100%;
          text-align: center;
          .top_text {
            height: 17px;
            font-size: 18px;
            margin-top: 43px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 17px;
          }
          .bottom_text {
            height: 14px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 14px;
            margin-top: 8px;
          }
        }
      }

      .logo_box {
        width: 62px;
        height: 62px;
        box-shadow: 0px 9px 8px -5px rgba(0, 0, 0, 0.2);
        border-radius: 14px;
        background-color: #fff;
        position: absolute;
        left: 29px;
        bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        .logo {
          transition: all 0.5s;
          width: 44px;
          height: 42px;
        }
      }
    }
    .right_card:nth-child(4n) {
      margin-right: 0;
    }
  }
}
</style>