<template>
  <div>
    <div class="user_info_item">
      <span :class="cardData.icon">
        <slot> 用户信息 </slot>
      </span>
      <span>{{ cardData.userInfo | mobileFilter }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardData: {
      type: Object,
    },
  },
  filters: {
    mobileFilter(val) {
      if (val * 1) {
        let reg = /^(.{3}).*(.{4})$/;
        return val.replace(reg, "$1****$2");
      } else {
        return val;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1920px) {
  .user_info_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 37px;
    height: 13px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 13px;

    span {
      transform: scale(0.9);
    }
  }
}
@media screen and (min-width: 1920px) {
  .user_info_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 46px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
  }
}
</style>