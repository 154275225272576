import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
  state: {
    user_id: 0,
    user_token: 0,
    head_img: '',
    nickname: '',

    language: '',
    skin_theme: '',
  },
  mutations: {
    set_user_info(state, user) {
      state.user_id = user.user_id
      state.user_token = user.user_token
      state.head_img = user.head_img
      state.nickname = user.nickname

      localStorage.setItem('user_id', user.user_id)
      localStorage.setItem('user_token', user.user_token)
      localStorage.setItem('head_img', user.head_img)
      localStorage.setItem('nickname', user.nickname)
    },
    logout(state) {
      state.user_id = ''
      state.user_token = ''
      state.head_img = ''
      state.nickname = ''

      localStorage.setItem('user_id', '')
      localStorage.setItem('user_token', '')
      localStorage.setItem('head_img', '')
      localStorage.setItem('nickname', '')

      router.push('/');
    },

    set_language(state, language) {
      state.language = language
      localStorage.setItem('language', language)
    },

    set_skin_theme(state, skin_theme) {
      state.skin_theme = skin_theme
      localStorage.setItem('skin_theme', skin_theme)
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    user_id(state) {
      return state.user_id ? state.user_id : localStorage.getItem('user_id')
    },
    user_token(state) {
      return state.user_token ? state.user_token : localStorage.getItem('user_token')
    },
    head_img(state) {
      return state.head_img ? state.head_img : localStorage.getItem('head_img')
    },
    nickname(state) {
      return state.nickname ? state.nickname : localStorage.getItem('nickname')
    },

    language(state) {
      let language = state.language ? state.language : localStorage.getItem('language')

      if (language == '汉语') return '简体中文'
      return language ? language : '简体中文'
    },
    
    skin_theme(state) {
      let skin_theme = state.skin_theme ? state.skin_theme : localStorage.getItem('skin_theme')
      return skin_theme ? skin_theme : 'one'
    }
  }
})

export default store