import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { lang } from "@/languagePackage/index.js";


// 使用ElementUI --start--
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// 使用ElementUI --end--

// 使用echarts  --start--
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts
// 使用echarts  --end--

// 中央事件总线  --start--
Vue.prototype.$bus = new Vue()
// 中央事件总线  --end--



Vue.config.productionTip = false
Vue.prototype.$lang = lang;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
