export const config = {
    web_name: 'xzd-AI建站',


    // web_host: 'http://jianzhan.dev.xinzhidi.cn',
    // web_host: 'http://localhost:9201',
    // web_host: 'http://web.yoo.cn',
    web_host: '',

    version_str: '0311A',

    // /index/webtemplate/details?template_id=3
    template_view_url: '/index/webtemplate/details',



    // /index.php/index/webpage/edit?page_id=12
    page_edit_url: '/index.php/index/webpage/edit',

    fileUploadApiUrl: '/index.php/api/common/upload/do_upload'
}