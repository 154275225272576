<template>
  <div class="user-box" :class="'skin_theme_' + skin_theme">
    <setNickname
      ref="setNickname"
      @callback="setNicknameCallback"
    ></setNickname>

    <div class="title-box">
      <div class="line"></div>
      <div>
        {{ $lang("userV2_right_left_userInfo_title") }}
      </div>
    </div>

    <div class="user-main-box">
      <div class="avatar-box">
        <el-upload
          :action="fileUploadApiUrl"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <div class="userLogoDiv">
            <img
              class="userLogo"
              :src="
                head_img
                  ? head_img
                  : 'http://jianzhan.dev.xinzhidi.cn/upload/20210926/7d7eb73889d4ee082a43eb7d6956fd2e.png'
              "
            />
            <el-button type="small" icon="el-icon-edit-outline">
              {{ $lang("userV2_right_left_userInfo_btn") }}
            </el-button>
          </div>
        </el-upload>
      </div>

      <div class="userinfo-box">
        <div class="userinfo">
          <div>
            <i class="iconfont icon-tel"></i>
            <p>{{ $lang("userV2_right_left_userInfo_list_title1") }}:</p>
          </div>
          <p class="userinfo-right">{{ phone | hideTel }}</p>
        </div>
        <div class="userinfo">
          <div>
            <i class="iconfont icon-account"></i>
            <p>{{ $lang("userV2_right_left_userInfo_list_title2") }}:</p>
          </div>
          <p class="userinfo-right">
            {{ nickname ? nickname : "尚未设置" }}
            <i @click="onEditNickname" class="el-icon-edit-outline"></i>
          </p>
        </div>
        <div class="userinfo">
          <div>
            <i class="iconfont icon-date"></i>
            <p>{{ $lang("userV2_right_left_userInfo_list_title3") }}:</p>
          </div>
          <p class="userinfo-right">{{ create_time_f | handel_c }}</p>
        </div>
      </div>

      <div class="logo-box">
        <img src="./big_logo.png" alt="logo" />
      </div>
    </div>
  </div>
</template>

<script>
import { config } from "@/config/index.js";

import { get_info, set_head_img } from "@/api/user.js";
import setNickname from "./c/dialog-edit-nickname.vue";

export default {
  components: {
    setNickname,
  },

  data() {
    return {
      loading: false,
      head_img: localStorage.getItem("userInfo-head_img"), //头像地址
      phone: localStorage.getItem("userInfo-phone"),
      nickname: localStorage.getItem("userInfo-nickname"),
      create_time_f: localStorage.getItem("userInfo-create_time_f"),

      fileUploadApiUrl: config.web_host + config.fileUploadApiUrl,
    };
  },
  filters: {
    //隐藏电话中间4位
    hideTel(value) {
      value = "" + value;
      let reg = /(\d{3})\d{4}(\d{4})/;
      return value.replace(reg, "$1****$2");
    },
    handel_c(str) {
      let s = str.substr(0, 10);
      return s;
    },
  },

  mounted() {
    this.getUserInfo();
  },
  methods: {
    // 上传头像
    handleAvatarSuccess(res, file) {
      //   console.log(res);
      if (res.code == 200) {
        this.head_img = res.data.file_url;
        this.setHeadImg(this.head_img);
      }
      //   this.head_img = URL.createObjectURL(file.raw);
    },
    // 头像类型限制
    beforeAvatarUpload(file) {
      //   const isJPG = file.type === "image/jpeg";
      //   const isLt2M = file.size / 1024 / 1024 < 2;
      //   if (!isJPG) {
      //     this.$message.error("上传头像图片只能是 JPG 格式!");
      //   }
      //   if (!isLt2M) {
      //     this.$message.error("上传头像图片大小不能超过 2MB!");
      //   }
      //   return isJPG && isLt2M;
    },

    getUserInfo() {
      this.loading = true;
      get_info({})
        .then((res) => {
          if (res.code == 200) {
            this.phone = res.data.user.phone;
            this.head_img = res.data.user.head_img;
            this.nickname = res.data.user.nickname;
            this.create_time_f = res.data.user.create_time_f;

            localStorage.setItem("userInfo-phone", res.data.user.phone);
            localStorage.setItem("userInfo-head_img", res.data.user.head_img);
            localStorage.setItem("userInfo-nickname", res.data.user.nickname);
            localStorage.setItem(
              "userInfo-create_time_f",
              res.data.user.create_time_f
            );
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },

    setHeadImg(head_img) {
      this.loading = true;
      let p = {
        head_img,
      };
      set_head_img(p)
        .then((res) => {
          if (res.code == 200) {
            let user = {
              user_id: localStorage.getItem("user_id"),
              user_token: localStorage.getItem("user_token"),
              head_img,
              nickname: localStorage.getItem("nickname"),
            };
            this.$store.commit("set_user_info", user);
            this.getUserInfo();
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },

    onEditNickname() {
      this.$refs.setNickname.start(this.nickname);
    },
    setNicknameCallback(nickname) {
      this.nickname = nickname;

      let user = {
        user_id: localStorage.getItem("user_id"),
        user_token: localStorage.getItem("user_token"),
        head_img: localStorage.getItem("head_img"),
        nickname,
      };
      this.$store.commit("set_user_info", user);
      this.getUserInfo();
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
    skin_theme() {
      return this.$store.getters.skin_theme;
    },
  },
};
</script>

<style lang="less" scoped>
.user-box {
  width: 100%;
  height: 100%;

  background-size: 100% 100% !important;
  position: relative;

  .user-main-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  // 标题
  .title-box {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    .line {
      width: 4px;
      height: 12px;
      border-radius: 6px;
      margin-right: 4px;
    }
  }
  // 头像
  .avatar-box {
    width: 100%;
    text-align: center;
    .userLogoDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      .userLogo {
        display: block;
        width: 64px;
        height: 64px;
        //   border: 1px dashed #999;
        border-radius: 50%;
      }

      .el-button {
        height: 22px;
        border: 0;
        border-radius: 6px;
        font-size: 12px;
        color: #fff;
        margin-top: 12px;
        padding: 0 3px;
        /deep/span {
          margin-left: 2px;
        }
      }
    }
  }

  .userinfo-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 50px 0;
    .userinfo {
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      height: 34px;
      align-items: center;
      color: #fff;
      font-size: 12px;
      margin-top: 18px;
      > div {
        display: flex;
        align-items: center;

        > i {
          margin-right: 8px;
          color: #ff7e28;
        }
      }

      .userinfo-right {
        color: #fff;
        font-size: 12px;

        i {
          cursor: pointer;
        }
      }
    }
  }

  // logo
  .logo-box {
    align-items: center;
    justify-content: center;
    > img {
      width: 159px;
      height: 159px;
    }
  }
}

.skin_theme_one.user-box {
  background: url("./left_bg_img.png") no-repeat;

  .title-box {
    color: #333;
    .line {
      background-color: #615bff;
    }
  }
  .el-button {
    background: #4943ff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 102, 0.3);
  }
}

.skin_theme_two.user-box {
  background: url("http://jianzhan.dev.xinzhidi.cn/upload/20211013/26d00b03d53bc4483bc7e0efeb9a28eb.png")
    no-repeat;
  .title-box {
    color: #fff;
    .line {
      background-color: #42dfc8;
    }
  }
  .el-button {
    background: #ec7a6b;
    box-shadow: 0px 2px 8px 0px rgba(21, 2, 0, 0.69);
  }
}
</style>