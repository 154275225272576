<template>
  <div>
    <myCenterLayout :select="'user'">
      <div class="bodyNav">
        <div class="dian"></div>
        <span>个人中心</span>
      </div>
    </myCenterLayout>
  </div>
</template>

<script>
import myCenterLayout from "@/components/layout/myCenter/index.vue";
export default {
  components: {
    myCenterLayout,
  },
};
</script>

<style lang="less" scoped>
.bodyNav {
  height: 90px;
  padding-left: 38px;
  display: flex;
  align-items: center;

  .dian {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #ff7e28;
    margin-right: 8px;
  }
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
</style>