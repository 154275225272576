<template>
  <div class="right_right_card">
    <img class="icon" :src="rightCardData.icon" />
    <p class="title">
      <slot>今日访客</slot>
    </p>
    <h3 class="peoples_num">{{ rightCardData.num }}</h3>
  </div>
</template>

<script>
export default {
  props: {
    rightCardData: {
      type: Object,
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1920px) {
  .right_right_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 120px;
    background: #ffffff;
    box-shadow: 0px 5px 10px 0px rgba(0, 18, 153, 0.2);
    border-radius: 20px;
    .icon {
      width: 30px;
      height: 30px;
    }
    .title,
    .peoples_num {
      color: var(--xzd_color5);
    }
    .title {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      margin-top: 14px;
      margin-bottom: 8px;
    }
    .peoples_num {
      font-size: 20px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
    }
  }
}
@media screen and (min-width: 1920px) {
  .right_right_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 124px;
    height: 176px;
    background: #ffffff;
    box-shadow: 0px 5px 10px 0px rgba(0, 18, 153, 0.2);
    border-radius: 20px;
    .icon {
      width: 44px;
      height: 44px;
    }
    .title,
    .peoples_num {
      color: var(--xzd_color5);
    }
    .title {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      margin-top: 21px;
      margin-bottom: 11px;
    }
    .peoples_num {
      font-size: 30px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
    }
  }
}
</style>