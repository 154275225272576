<template>
  <div class="test_right">
    <!-- title -->
    <div class="title_box" v-if="titleClose">
      <div class="dot"></div>
      <div>
        <span class="index_crumbs" @click="goToIndex">极简建站</span> /
        <span class="cur_crumbs">智能推荐</span>
      </div>
      <i class="el-icon-circle-close icon" @click="closeTitle"></i>
    </div>
    <!-- banner -->
    <div class="banner">
      <h2 class="title">AI智能建站推荐模版</h2>
      <p class="subtitle">
        AI intelligent website building template is recommended
      </p>
    </div>
    <!-- card -->
    <div class="card_box">
      <rightCard></rightCard>
    </div>
    <!-- button -->
    <div class="right_button_box">
      <el-button type="primary" icon="el-icon-search">再搜一次</el-button>
    </div>
  </div>
</template>

<script>
import rightCard from "./rightCard.vue";
export default {
  components: {
    rightCard,
  },
  data() {
    return {
      titleClose: true,
    };
  },
  methods: {
    closeTitle() {
      this.titleClose = false;
    },
    goToIndex() {
      this.$router.push("/index/search/index");
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1920px) {
  .test_right {
    width: 100%;
    height: 100%;
    padding: 23px 26px;
    overflow-y: auto;
    // 标题
    .title_box {
      display: flex;
      align-items: center;
      color: var(--xzd_color3);
      font-size: 12px;
      .dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #ff7e28;
        margin-right: 6px;
      }
      .cur_crumbs,
      .index_crumbs {
        cursor: pointer;
      }
      .cur_crumbs {
        color: var(--xzd_color3);
        font-weight: bold;
      }
      .icon {
        margin-left: 6px;
        color: #ff7e28;
        font-size: 12px;
        cursor: pointer;
      }
    }
    // 横幅
    .banner {
      width: 100%;
      height: 117px;
      margin-top: 21px;
      border-radius: 16px;
      background: url("~@/views/test/yyq/test_images/right_bg_img.png")
        no-repeat;
      background-size: cover;
      background-position: center;
      padding-top: 29px;

      .title {
        text-align: center;
        height: 34px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 34px;
        letter-spacing: 15px;
      }
      .subtitle {
        text-align: center;
        margin-top: 9px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
        letter-spacing: 2px;
      }
    }

    // card
    .card_box {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    // right_button_box
    .right_button_box {
      margin-top: 8px;
      text-align: center;
      /deep/.el-button {
        width: 127px;
        height: 27px;
        background: #4943ff;
        border-color: #4943ff;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 102, 0.3);
        border-radius: 7px;
        padding: 0;
        font-size: 12px;
      }
    }
  }
}
@media screen and (min-width: 1920px) {
  .test_right {
    width: 100%;
    height: 100%;
    padding: 34px 38px;
    overflow-y: auto;
    // 标题
    .title_box {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: var(--xzd_color3);
      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #ff7e28;
        margin-right: 10px;
      }
      .cur_crumbs,
      .index_crumbs {
        cursor: pointer;
      }
      .cur_crumbs {
        color: var(--xzd_color3);
        font-weight: bold;
      }
      .icon {
        margin-left: 10px;
        color: #ff7e28;
        font-size: 12px;
        cursor: pointer;
      }
    }
    // 横幅
    .banner {
      width: 100%;
      height: 173px;
      margin-top: 21px;
      border-radius: 16px;
      background: url("~@/views/test/yyq/test_images/right_bg_img.png")
        no-repeat;
      background-size: cover;
      background-position: center;
      padding-top: 43px;

      .title {
        text-align: center;
        height: 50px;
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 50px;
        letter-spacing: 23px;
      }
      .subtitle {
        text-align: center;
        margin-top: 13px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 25px;
        letter-spacing: 3px;
      }
    }

    // card
    .card_box {
      margin-top: 44px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    // right_button_box
    .right_button_box {
      margin-top: 8px;
      text-align: center;
      /deep/.el-button {
        width: 127px;
        height: 27px;
        background: #4943ff;
        border-color: #4943ff;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 102, 0.3);
        border-radius: 7px;
        padding: 0;
        font-size: 12px;
      }
    }
  }
}
</style>