<template>
  <div class="user_left">
    <!-- 标题 -->
    <div class="title_box">
      <div class="line"></div>
      <div>
        {{ languagePackage[language].userV2_right_left_userInfo_title }}
      </div>
    </div>
    <!-- 头像 -->
    <div class="avatar">
      <el-upload
        :action="fileUploadApiUrl"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
      >
        <div class="userLogoDiv">
          <img
            class="userLogo"
            :src="
              head_img
                ? head_img
                : 'http://jianzhan.dev.xinzhidi.cn/upload/20210926/7d7eb73889d4ee082a43eb7d6956fd2e.png'
            "
          />
          <el-button type="small" icon="el-icon-edit-outline">
            {{ languagePackage[language].userV2_right_left_userInfo_btn }}
          </el-button>
        </div>
      </el-upload>
    </div>
    <!-- 用户信息 -->
    <div class="user_info">
      <rightUserLeftCard
        :cardData="item"
        v-for="(item, i) in cardData"
        :key="item.title"
      >
        {{ languagePackage[language].userV2_right_left_userInfo_list_title[i] }}
      </rightUserLeftCard>
    </div>
    <!-- logo -->
    <div class="logo">
      <img src="../test_images/big_logo.png" alt="logo" />
    </div>
  </div>
</template>

<script>
import { config } from "@/config/index.js";
import languagePackage from "@/languagePackage/index.js";
import rightUserLeftCard from "./rightUserLeftCard.vue";

export default {
  components: {
    rightUserLeftCard,
  },
  data() {
    return {
      languagePackage,
      head_img: "", //头像地址
      fileUploadApiUrl: config.web_host + config.fileUploadApiUrl,
      // 卡片数据
      cardData: [
        {
          title: "用户名",
          icon: "el-icon-user-solid",
          userInfo: "樱桃小丸子",
        },
        {
          title: "手机号",
          icon: "el-icon-user-solid",
          userInfo: "18123061515",
        },
        {
          title: "创建时间",
          icon: "el-icon-user-solid",
          userInfo: "2021-8-17 15:40:59",
        },
      ],
    };
  },
  methods: {
    // 上传头像
    handleAvatarSuccess(res, file) {
      //   console.log(res);
      if (res.code == 200) {
        this.head_img = res.data.file_url;
        this.setHeadImg(this.head_img);
      }
      //   this.head_img = URL.createObjectURL(file.raw);
    },
    // 头像类型限制
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1920px) {
  .user_left {
    width: 100%;
    height: 100%;
    background: url("../test_images/left_bg_img.png") no-repeat;
    background-size: 100% 100% !important;
    // 标题
    .title_box {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: var(--xzd_color5);
      .line {
       width: 4px;
      height: 12px;
        border-radius: 6px;
        background-color: #666aff;
        margin-right: 4px;
      }
    }
    // 头像
    .avatar {
      width: 100%;
      margin-top: 81px;
      text-align: center;
      .userLogoDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        .userLogo {
          display: block;
          width: 64px;
          height: 64px;
          //   border: 1px dashed #999;
          border-radius: 50%;
        }

        .el-button {
          height: 22px;
          background-color: var(--xzd_color6);
          border-color: var(--xzd_color6);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 102, 0.3);
          border-radius: 6px;
          font-size: 12px;
          color: #fff;
          margin-top: 12px;
          padding: 0 3px;
          /deep/span {
            margin-left: 2px;
          }
        }
      }
    }
    // 用户信息
    .user_info {
      padding: 0 16px;
    }
    // logo
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 98px;
      > img {
        width: 159px;
        height: 159px;
      }
    }
  }
}
@media screen and (min-width: 1920px) {
  .user_left {
    width: 100%;
    height: 100%;
    background: url("../test_images/left_bg_img.png") no-repeat;
    background-size: 100% 100% !important;
    // 标题
    .title_box {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: var(--xzd_color5);
      .line {
       width: 4px;
      height: 12px;
        border-radius: 6px;
        background-color: #666aff;
        margin-right: 4px;
      }
    }
    // 头像
    .avatar {
      width: 100%;
      margin-top: 125px;
      text-align: center;
      .userLogoDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        .userLogo {
          display: block;
          width: 94px;
          height: 94px;
          //   border: 1px dashed #999;
          border-radius: 50%;
        }

        .el-button {
          height: 28px;
          background-color: var(--xzd_color6);
          border-color: var(--xzd_color6);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 102, 0.3);
          border-radius: 6px;
          font-size: 12px;
          color: #fff;
          margin-top: 18px;
          padding: 0 4px;
        }
      }
    }
    // 用户信息
    .user_info {
      padding: 0 20px;
    }
    // logo
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 106px;
      > img {
        width: 231px;
        height: 233px;
      }
    }
  }
}
</style>