<template>
  <div>
    <div :class="type == 'index' ? '' : 'myHeaderDivFather'">
      <div class="myHeaderDiv">
        <div class="divLeft" @click="goIndex()">
          <!-- <img v-if="type != 'index'" :src="logo1" class="logo" /> -->
          <!-- <img v-if="type == 'index'" :src="logo2" class="logo" /> -->
          <span v-if="type != 'index'">{{ $lang("web_logo_title") }}</span>
          <span v-if="type == 'index'" style="color: #fff">{{
            $lang("web_logo_title")
          }}</span>
        </div>
        <div class="divCenter">
          <span :class="type == 'index' ? 'fontColorWhite' : ''">
            {{ $lang("hope") }}</span
          >
        </div>
        <div class="divRight">
          <div>
            <span
              style="
                border: 1px solid #a9a9a9;
                border-radius: 20px;
                padding: 4px;
                margin-right: 20px;
              "
            >
              <el-dropdown>
                <span
                  style="outline: none"
                  :class="type == 'index' ? 'fontColorWhite' : ''"
                >
                  {{ $store.getters.language }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" style="width: 100px">
                  <el-dropdown-item
                    @click.native="$store.commit('set_language', '简体中文')"
                    >简体中文</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="$store.commit('set_language', 'english')"
                    >english</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </div>

          <template v-if="user_id">
            <user-name v-if="user_id">
              <span :class="type == 'index' ? 'fontColorWhite' : ''">
                <i class="el-icon-user"></i>
                {{ nickname ? nickname : "暂无昵称" }}</span
              >
            </user-name>
          </template>

          <template v-if="!user_id">
            <span
              v-if="type == 'index'"
              @click="$router.push('/login')"
              style="color: #fff"
            >
              <i class="el-icon-user"></i>

              {{ $lang("go_login") }}
            </span>
            <span v-if="type != 'index'" @click="$router.push('/login')">
              <i class="el-icon-user"></i>

              {{ $lang("go_login") }}
            </span>
            &nbsp; &nbsp;
          </template>
        </div>
      </div>
    </div>

    <div style="width: 100vw; height: 60px"></div>
  </div>
</template>

<script>
import userName from "./c/userName.vue";
export default {
  components: {
    userName,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      logo1: require("@/assets/imgs/common/logo.svg"),
      logo2: require("@/assets/imgs/indexIndexIndex/logo2.png"),
      userName,
    };
  },
  computed: {
    user_id() {
      return this.$store.getters.user_id;
    },
    nickname() {
      return this.$store.getters.nickname;
    },
  },
  methods: {
    goIndex() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="less" scoped>
.myHeaderDivFather {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 60px;
  z-index: 1000;
  border-bottom: 1px solid #eee;
}
.myHeaderDiv {
  .fontColorWhite {
    color: #fff;
  }
  cursor: pointer;
  width: 1300px;
  height: 60px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 60px;

  .divLeft {
    width: 350px;
    display: flex;
    font-size: 20px;
    text-align: left;
    .logo {
      height: 45px;
      display: block;
    }
  }
  .divCenter {
    width: 600px;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    .fontColorWhite {
      color: #fff;
    }
  }
  .divRight {
    width: 350px;
    text-align: right;

    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>