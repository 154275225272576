<template>
  <div class="test_index">
    <!-- 头部 -->
    <div class="max_header">
      <testHeader></testHeader>
    </div>
    <!-- 身体 -->
    <div class="main_box">
      <div class="main">
        <!-- 左侧 -->
        <div class="left">
          <testLeft></testLeft>
        </div>
        <!-- 右侧 -->
        <div class="right">
          <testRight></testRight>
        </div>
      </div>
      <div class="right_blank"></div>
    </div>
    <el-button
      style="position: fixed; left: 50%; top: 50%; z-index: 99999"
      @click="changeColor"
    >
      换肤
    </el-button>
  </div>
</template>

<script>
import testHeader from "@/views/test/yyq/c/testHeader.vue";
import testLeft from "@/views/test/yyq/c/testLeft.vue";
import testRight from "@/views/test/yyq/c/testRight.vue";
import { Changetheme, initialTheme } from "@/views/test/yyq/testUtils/theme.js";

export default {
  components: {
    testHeader,
    testLeft,
    testRight,
  },
  data() {
    return {
      isChangeTheme: false,
    };
  },
  mounted() {
    // 初始化
    let theme = JSON.parse(window.localStorage.getItem("theme"));
    if (!theme) {
      initialTheme();
      return;
    } else {
      if (theme === "blankTheme") {
        Changetheme();
        return;
      }
      if (theme === "whiteTheme") {
        initialTheme();
        return;
      }
    }
  },
  methods: {
    // 换肤按钮
    changeColor() {
      this.isChangeTheme = !this.isChangeTheme;
      if (this.isChangeTheme) {
        // 防止后续传入的不是字符串
        window.localStorage.setItem("theme", JSON.stringify("blankTheme"));
        // 暗黑主题
        Changetheme();
      } else {
        window.localStorage.setItem("theme", JSON.stringify("whiteTheme"));
        // 光明主题
        initialTheme();
      }
    },
  },
};
</script>

<style lang="less" scoped>
body {
  --xzd_color1: #f3f5ff !important;
  --xzd_color2: #ebefff !important;
  --xzd_color3: #333 !important;
  --xzd_color4: #615bff !important;
  --xzd_color5: #aeabff !important;
  --xzd_color6: #4943ff !important;
}
@media screen and (max-width: 1920px) {
  .max_header {
    width: 100%;
    background-color: var(--xzd_color1);
    color: var(--xzd_color3);
  }
  .test_index {
    background: var(--xzd_color1);
    .right_blank {
      display: block;
      width: calc(100vw - 1300px);
      height: calc(100vh - 72px);
      background: var(--xzd_color2);
      position: absolute;
      right: 0;
      top: 72px;
    }
    .main_box {
      background-color: var(--xzd_color1);
    }
    // 身体
    .main {
      display: flex;
      width: 1300px;
      margin: 0 auto;
      height: calc(100vh - 72px);
      padding: 0 0 0 26px;
      position: relative;
      z-index: 1111;

      // 左侧
      .left {
        width: 212px;
        height: calc(100vh - 72px);
        margin-right: 8px;
      }

      // 右侧
      .right {
        flex: 1;
        background: var(--xzd_color2);
        border-top-left-radius: 20px;
      }
    }
  }
}
@media screen and (min-width: 1920px) {
  .max_header {
    width: 100%;
    background-color: var(--xzd_color1);
  }
  .test_index {
    background: var(--xzd_color1);
    box-sizing: border-box;
    .right_blank {
      display: block;
      width: calc(100vw - 1920px);
      height: calc(100vh - 105px);
      background: var(--xzd_color2);
      position: absolute;
      right: 0;
      top: 105px;
    }
    // 身体
    .main {
      display: flex;
      width: 1920px;
      margin: 0 auto;
      height: calc(100vh - 105px);
      padding: 0 0 0 38px;
      position: relative;
      z-index: 1111;

      // 左侧
      .left {
        width: 314px;
        height: calc(100vh - 105px);
        margin-right: 12px;
        position: relative;
        top: -1px;
      }

      // 右侧
      .right {
        flex: 1;
        background: var(--xzd_color2);
        border-top-left-radius: 20px;
      }
    }
  }
}
</style>