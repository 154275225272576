// 更改主题
export const Changetheme = () => {
    document.body.style.setProperty("--xzd_color1", "#151417");
    document.body.style.setProperty("--xzd_color2", "#232227");
    document.body.style.setProperty("--xzd_color3", "#fff");
    document.body.style.setProperty("--xzd_color4", "#fff");
    document.body.style.setProperty("--xzd_color5", "#404040");
    document.body.style.setProperty("--xzd_color6", "#EC7A6B");
    document.querySelector(
        ".test_left"
    ).style.background = `url("http://jianzhan.dev.xinzhidi.cn/upload/20211013/26d00b03d53bc4483bc7e0efeb9a28eb.png") no-repeat`;
    document.querySelectorAll(".card_bottom").forEach((v) => {
        v.style.background = `url("http://jianzhan.dev.xinzhidi.cn/upload/20211013/870e039035bd9eca1bc7e115c6b1e729.png") no-repeat`;
    });
    document
        .querySelector(".logo")
        .setAttribute(
            "src",
            "http://jianzhan.dev.xinzhidi.cn/upload/20211013/7b2a6f1c4f3f7fd2253521d63c45f57e.png"
        );
}
// 初始化
export const initialTheme = () => {
    document.body.style.setProperty("--xzd_color1", "#f3f5ff");
    document.body.style.setProperty("--xzd_color2", "#ebefff");
    document.body.style.setProperty("--xzd_color3", "#333");
    document.body.style.setProperty("--xzd_color4", "#615bff");
    document.body.style.setProperty("--xzd_color5", "#aeabff");
    document.body.style.setProperty("--xzd_color6", "#4943ff");
    document.querySelector(
        ".test_left"
    ).style.background = `url("http://jianzhan.dev.xinzhidi.cn/upload/20211013/70ace25039bd4ae205870370cd01e4f4.png") no-repeat`;
    document.querySelectorAll(".card_bottom").forEach((v) => {
        v.style.background = `url("http://jianzhan.dev.xinzhidi.cn/upload/20211013/94716e85d4c83aafaf6c5005e6386b6f.png") no-repeat`;
    });
    document
        .querySelector(".logo")
        .setAttribute(
            "src",
            "http://jianzhan.dev.xinzhidi.cn/upload/20211013/15ca993f35468bb9c28f423ac28e744b.png"
        );
}
