<template>
  <div class="right_right_bottom">
    <!-- 左侧 -->
    <div class="left">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="网站一" name="one">
          <div class="echarts-box">
            <div id="rightRightTopEcharts1" class="echarts"></div>
            <div class="btn-box">
              <el-button>
                {{
                  languagePackage[language].userV2_right_right_userCenter_btns1
                }}
              </el-button>
              <div></div>
              <el-button>
                {{
                  languagePackage[language].userV2_right_right_userCenter_btns2
                }}
              </el-button>
              <div></div>
              <el-button>
                {{
                  languagePackage[language].userV2_right_right_userCenter_btns3
                }}
              </el-button>
            </div>
            <div class="line"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="网站二" name="two">配置 </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 右侧 -->
    <div class="right">
      <rightRightCard
        :rightCardData="item"
        v-for="(item, i) in rightCardData"
        :key="item.title"
      >
        {{
          languagePackage[language].userV2_right_right_userCenter_cardTitle[i]
        }}
      </rightRightCard>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import rightRightCard from "./rightRightCard.vue";
import languagePackage from "@/languagePackage/index.js";

export default {
  components: {
    rightRightCard,
  },
  data() {
    return {
      languagePackage,
      rightCardData: [
        {
          icon: require("../test_images/peoples.png"),
          title: "今日访客",
          num: "2675",
        },
        {
          icon: require("../test_images/sum_peoples.png"),
          title: "累计访客",
          num: "2675",
        },
      ],
      activeName: "one",
      titleColor: "#aaa",
    };
  },
  methods: {
    // 网站一访客统计
    VisitorStatistics(titleColor) {
      let option = {
        //标题
        title: {
          text: "访客统计",
          left: 38,
          top: 15,
          textStyle: {
            //文字颜色
            color: titleColor,
            //字体风格
            fontStyle: "normal",
            //字体粗细
            fontWeight: "700",
            //字体系列
            fontFamily: "PingFangSC-Medium, PingFang SC",
            //字体大小
            fontSize: 12,
          },
        },
        //提示框
        tooltip: {
          trigger: "axis",
        },
        //图例
        legend: {
          data: ["访客统计"],
          top: 15,
        },
        //直角坐标系
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        //x轴
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "0时",
            "1时",
            "2时",
            "3时",
            "4时",
            "5时",
            "6时",
            "7时",
            "8时",
            "9时",
            "10时",
            "11时",
            "12时",
            "13时",
            "14时",
            "15时",
            "16时",
            "17时",
            "18时",
            "19时",
            "20时",
            "21时",
            "22时",
            "23时",
            "24时",
          ],
          axisLabel: {
            interval: 0,
            // formatter: function (value) {
            //   //x轴的文字改为竖版显示
            //   var str = value.split("");
            //   if (value.length > 2) {
            //     let newValue = value.slice(0, 2) * 1;
            //     return newValue + "\n" + "时";
            //   }
            //   return str.join("\n");
            // },
          },
        },
        //y轴
        yAxis: {
          type: "value",
          // 分割线为虚线
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        //核心数据
        series: [
          {
            data: [
              20, 43, 90, 34, 90, 30, 13, 32, 24, 22, 53, 53, 53, 53, 35, 35,
              53, 35, 43, 54, 54, 46, 75, 67, 33,
            ],
            type: "line",
            // symbol: "none",
            smooth: true,
            showSymbol: false,

            areaStyle: {
              normal: {
                //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#FF7523", // 0% 处的颜色
                  },
                  //   {
                  //     offset: 0.5,
                  //     color: "#D1ECD4", // 50% 处的颜色
                  //   },
                  {
                    offset: 1,
                    color: "#FFF8EC", // 100% 处的颜色
                  },
                ]), //背景渐变色
              },
            },
            itemStyle: {
              normal: {
                color: "#FF7523", //改变折线点的颜色
                lineStyle: {
                  color: "#FF7523", //改变折线颜色
                },
              },
            },
          },
        ],
      };
      this.rightRightTopEcharts1.setOption(option);
    },
    // echarts自适应
    resize() {
      this.rightRightTopEcharts1.resize();
    },
  },
  mounted() {
    this.rightRightTopEcharts1 = echarts.init(
      document.getElementById("rightRightTopEcharts1")
    );
    this.VisitorStatistics(this.titleColor);
    // 监听窗口大小
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    // 解绑挂在window上事件
    window.removeEventListener("resize", this.resize);
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1920px) {
  .right_right_bottom {
    margin-top: 35px;
    width: 100%;
    height: 280px;
    display: flex;

    .left {
      // flex: 1;
      width: 761px;

      .el-button:hover {
        color: #ff7523 !important;
      }
      .el-button:active {
        color: #ff7523 !important;
      }
      /deep/.el-tabs__header {
        margin: 0;
        .el-tabs__nav {
          border: 0;
        }
      }
      /deep/.el-tabs__nav-wrap {
        background-color: var(--xzd_color3);
        .el-tabs__item {
          width: 132px;
          height: 51px;
          text-align: center;
          line-height: 28px;
          background-color: var(--xzd_color6);
          border-radius: 12px 12px 0px 0px;
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          cursor: pointer;
          opacity: 0.3;
          &.is-active {
            background-color: var(--xzd_color6);
            opacity: 1;
          }
        }
      }
      /deep/.el-tabs__content {
        top: -25px;
        z-index: 999;
        padding: 1px;
        box-shadow: 0px 3px 7px 0px rgba(0, 18, 153, 0.2);
        border-radius: 20px;
      }
      .line {
        position: absolute;
        left: 34px;
        top: 19px;
        width: 4px;
      height: 12px;
        background: #666aff;
        border-radius: 6px;
      }
      .echarts-box {
        position: relative;
        border-radius: 20px;
        background-color: var(--xzd_color3);

        .echarts {
          width: 731px;
          height: 254px;
        }
        .btn-box {
          position: absolute;
          right: 12px;
          top: 6px;
          display: flex;
          align-items: center;

          > div {
            width: 1px;
            height: 10px;
            background-color: var(--xzd_color3);
            border-radius: 2px;
          }
          .el-button {
            color: #333;
            border: 0;
            background-color: var(--xzd_color3);
            color: var(--xzd_color8);
            font-size: 12px;
          }
        }
      }
    }
    .right {
      width: 84px;
      height: 254px;
      margin-top: 26px;
      margin-left: 19px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
@media screen and (min-width: 1920px) {
  .right_right_bottom {
    margin-top: 52px;
    width: 100%;
    height: 417px;
    display: flex;

    .left {
      // flex: 1;
      width: 1124px;

      .el-button:hover {
        color: #ff7523 !important;
      }
      .el-button:active {
        color: #ff7523 !important;
      }
      /deep/.el-tabs__header {
        margin: 0;
      }
      /deep/.el-tabs__nav-wrap {
        background-color: var(--xzd_color3);
        .el-tabs__item {
          width: 195px;
          height: 76px;
          text-align: center;
          line-height: 56px;
          background-color: var(--xzd_color6);
          border-radius: 18px 18px 0px 0px;
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          cursor: pointer;
          opacity: 0.3;
          &.is-active {
            opacity: 1;
            background-color: var(--xzd_color6);
          }
        }
      }
      /deep/.el-tabs__content {
        top: -25px;
        z-index: 999;
        background-color: var(--xzd_color3);
        box-shadow: 0px 5px 10px 0px rgba(0, 18, 153, 0.2);
        border-radius: 30px;
        padding: 1px;
      }
      .line {
        position: absolute;
        left: 30px;
        top: 17px;
       width: 4px;
      height: 12px;
        background: #666aff;
        border-radius: 6px;
      }
      .echarts-box {
        border-radius: 30px;
        position: relative;
        background-color: var(--xzd_color3);

        .echarts {
          width: 1124px;
          height: 376px;
        }
        .btn-box {
          position: absolute;
          right: 22px;
          top: 6px;
          display: flex;
          align-items: center;
          > div {
            width: 1px;
            height: 10px;
            background-color: var(--xzd_color3);
            border-radius: 2px;
          }
          .el-button {
            color: #333;
            border: 0;
            background-color: var(--xzd_color3);
            color: var(--xzd_color8);
          }
        }
      }
    }
    .right {
      width: 124px;
      height: 376px;
      margin-top: 41px;
      margin-left: 28px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>