<template>
  <div class="test_search">
    <div class="title_box">
      <div class="asterisk">*</div>
      <div class="title">您的行业：金融行业</div>
    </div>
    <div>
      <search>网站用途：</search>
      <search>网站类型：</search>
      <search>整体风格：</search>
    </div>
    <div class="button_box">
      <el-button type="primary" icon="el-icon-search">搜索</el-button>
    </div>
    <div class="bottom_img">
      <img class="house" src="../test_images/house.png" alt="" />
    </div>
  </div>
</template>

<script>
import search from "./search.vue";
export default {
  components: {
    search,
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1920px) {
  .test_search {
    width: 176px;
    margin-top: 73px;
    // 标题
    .title_box {
      position: relative;
      .asterisk {
        color: #ff7e28;
        margin-right: 0;
        font-size: 12px;
        position: absolute;
        top: 0;
        left: -6px;
      }
      .title {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 13px;
      }
    }
    // 按钮
    .button_box {
      margin-top: 42px;
      text-align: center;
      /deep/.el-button--primary {
        width: 87px;
        height: 24px;
        padding: 0;
        background: var(--xzd_color6);
        border-color: var(--xzd_color6);
        font-size: 12px;
        color: #fff;
      }
      /deep/.el-button--primary:hover {
        background: #6f5fff;
        border-color: #6f5fff;
      }
    }

    // 下方图片
    .bottom_img {
      margin-top: 30px;
      .house {
        width: 186px;
        height: 186px;
      }
    }
  }
}
@media screen and (min-width: 1920px) {
  .test_search {
    width: 274px;
    margin-top: 110px;
    // 标题
    .title_box {
      position: relative;
      .asterisk {
        color: #ff7e28;
        margin-right: 0;
        font-size: 14px;
        position: absolute;
        top: 0;
        left: -6px;
      }
      .title {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 13px;
      }
    }
    // 按钮
    .button_box {
      margin-top: 61px;
      text-align: center;
      /deep/.el-button--primary {
        width: 128px;
        height: 34px;
        padding: 0;
        background: var(--xzd_color6);
        border-color: var(--xzd_color6);
        font-size: 14px;
        color: #fff;
      }
      /deep/.el-button--primary:hover {
        background: #6f5fff;
        border-color: #6f5fff;
      }
    }

    // 下方图片
    .bottom_img {
      margin-top: 45px;
      .house {
        width: 274px;
        height: 274px;
      }
    }
  }
}
</style>