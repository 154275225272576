<template>
  <div class="search">
    <div class="title_box">
      <div class="asterisk">*</div>
      <div class="title">
        <slot>标题</slot>
      </div>
    </div>
    <el-input
      :placeholder="slotText"
      suffix-icon="el-icon-search"
      v-model="input"
    >
    </el-input>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: "",
      slotText: "",
    };
  },
  created() {
    let str = this.$slots.default[0].text;
    this.slotText = "请输入您的" + str.substring(0, str.length - 1);
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1920px) {
  .search:first-child {
    margin-top: 27px;
  }
  .search {
    margin-top: 22px;
    .title_box {
      position: relative;
      .asterisk {
        color: #ff7e28;
        margin-right: 0;
        font-size: 12px;
        position: absolute;
        top: 0;
        left: -6px;
      }
      .title {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 13px;
      }
    }
    /deep/.el-input__inner {
      height: 28px;
      margin-top: 4px;
      border-radius: 8px;
      background-color: var(--xzd_color5);
      border: 1px solid var(--xzd_color5);
    }
    /deep/.el-input__inner:focus {
      border: 1px solid #409eff;
    }
    /deep/.el-input__inner::placeholder {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #fff;
    }
  }
}
@media screen and (min-width: 1920px) {
  .search:first-child {
    margin-top: 39px;
  }
  .search {
    margin-top: 34px;
    .title_box {
      position: relative;
      .asterisk {
        color: #ff7e28;
        margin-right: 0;
        font-size: 12px;
        position: absolute;
        top: 0;
        left: -6px;
      }
      .title {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 13px;
      }
    }
    /deep/.el-input__inner {
      height: 39px;
      margin-top: 5px;
      border-radius: 8px;
      background-color: var(--xzd_color5);
      border: 1px solid var(--xzd_color5);
    }
    /deep/.el-input__inner:focus {
      border: 1px solid #409eff;
    }
    /deep/.el-input__inner::placeholder {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #fff;
    }
  }
}
</style>