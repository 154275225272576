import request from '@/utils/request'

export const get_info = (params) => {
    let url = "/index.php/api/v1_0_0.user/get_info";
    return request.post(url, params)
}

export const set_head_img = (params) => {
    let url = "/index.php/api/v1_0_0.user/set_head_img";
    return request.post(url, params)
}

export const set_nickname = (params) => {
    let url = "/index.php/api/v1_0_0.user/set_nickname";
    return request.post(url, params)
}