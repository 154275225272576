<template>
  <div :class="'skin_theme_' + skin_theme">
    <el-dropdown>
      <span>
        {{ language }}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown" style="width: 100px">
        <el-dropdown-item
          @click.native="$store.commit('set_language', '简体中文')"
          >简体中文</el-dropdown-item
        >

        <el-dropdown-item
          @click.native="$store.commit('set_language', '繁体中文')"
          >繁体中文</el-dropdown-item
        >

        <el-dropdown-item
          @click.native="$store.commit('set_language', 'english')"
          >English</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  computed: {
    language() {
      return this.$store.getters.language;
    },
    skin_theme() {
      return this.$store.getters.skin_theme;
    },
  },
};
</script>

<style lang="less" scoped>
.skin_theme_one {
  /deep/.el-dropdown {
    color: #333;
    font-size: 12px;
  }
}
.skin_theme_two {
  /deep/.el-dropdown {
    color: #fff;
    font-size: 12px;
  }
}
</style>