<template>
  <div class="test_left">
    <div class="title_box">
      <div class="line"></div>
      <h3 class="title">智能搜索</h3>
    </div>
    <div class="search_box">
      <testSearch></testSearch>
    </div>
  </div>
</template>

<script>
import testSearch from "./testSearch.vue";
export default {
  components: {
    testSearch,
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1920px) {
  .test_left {
    width: 212px;
    height: 630px;
    background: url("~@/views/test/yyq/test_images/left_bg_img.png") no-repeat;
    background-size: 100% 100% !important;
    // 标题
    .title_box {
      display: flex;
      align-items: center;
      .line {
        width: 4px;
        height: 12px;
        background: #615bff;
        border-radius: 4px;
        margin-right: 4px;
      }
      .title {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: var(--xzd_color3);
        font-weight: 700;
      }
    }
    // 搜索
    .search_box {
      display: flex;
      justify-content: center;
    }
  }
}
@media screen and (min-width: 1920px) {
  .test_left {
    width: 314px;
    height: 930px;
    background: url("~@/views/test/yyq/test_images/left_bg_img.png") no-repeat;
    background-size: 100% 100% !important;
    // 标题
    .title_box {
      display: flex;
      align-items: center;
      .line {
        width: 4px;
        height: 12px;
        background: #615bff;
        border-radius: 6px;
        margin-right: 6px;
      }
      .title {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: var(--xzd_color3);
        font-weight: 700;
      }
    }
    // 搜索
    .search_box {
      display: flex;
      justify-content: center;
    }
  }
}
</style>