<template>
  <div class="fatherDiv">
    <div class="son1">
      <myheader :type="'index'" />
    </div>

    <div class="son2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import moshi from "@/components/indexIndexIndex/moshi.vue";
import myheader from "@/components/common/myHeader/index.vue";

export default {
  components: {
    moshi,
    myheader,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.fatherDiv {
  width: 100%;
  min-width: 1300px;
  height: 100vh;
  position: relative;

  .son1 {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .son2 {
    width: 100%;
    height: 100vh;
    background: url("~@/assets/imgs/indexIndexIndex/bg-img.png");
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>