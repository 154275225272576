import Vue from 'vue'
import VueRouter from 'vue-router'
import { config } from '@/config/index.js'

// 测试页面
import testXysTest1 from '@/views/test/xys/test1.vue'
import testXysTest2 from '@/views/test/xys/test2.vue'
import testYyqTest1 from '@/views/test/yyq/test1.vue'
import testYyqTest2 from '@/views/test/yyq/test2.vue'
import testLydTest1 from '@/views/test/lyd/test1.vue'
import testLydTest2 from '@/views/test/lyd/test2.vue'

// 首页
import indexIndexIndex from '@/views/index/index/index.vue'
Vue.use(VueRouter)

//解决路由跳转报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


const routes = [
    // 测试页面
    { path: '/test/xys/test1', name: 'test1', component: testXysTest1 },
    { path: '/test/xys/test2', name: 'test2', component: testXysTest2, },
    { path: '/test/yyq/test1', name: 'test3', component: testYyqTest1, },
    { path: '/test/yyq/test2', name: 'test4', component: testYyqTest2, },
    { path: '/test/lyd/test1', name: 'test5', component: testLydTest1, },
    { path: '/test/lyd/test2', name: 'test6', component: testLydTest2, },


    // 极简建站 - list
    // { path: '/', name: 'searchIndex', component: () => import('@/views/index/search/index.vue'), },
    // { path: '/index/search/index', name: 'searchIndex_', component: () => import('@/views/index/search/index.vue'), },
    { path: '/index/search/details', name: 'searchDetails', component: () => import('@/views/index/search/details.vue'), },

    // 极简建站 - list V2
    { path: '/', name: 'searchIndexV2', component: () => import('@/views/v2/index/index.vue'), },
    { path: '/index/search/indexV2', name: 'searchIndexV2_', component: () => import('@/views/index/search/indexV2.vue'), },

    // 注册页面
    { path: '/register', name: 'register', component: () => import('@/views/index/index/register.vue'), },
    { path: '/index/index/register', name: 'register_', component: () => import('@/views/index/index/register.vue') },

    // 登录页面
    { path: '/login', name: 'login', component: () => import('@/views/index/index/login.vue'), },
    { path: '/index/index/login', name: 'login_', component: () => import('@/views/index/index/login.vue'), },

    // 忘记密码页面
    { path: '/forget', name: 'forget', component: () => import('@/views/index/index/forget.vue'), },
    { path: '/index/index/forget', name: 'forget_', component: () => import('@/views/index/index/forget.vue'), },


    // 个人中心页面
    // {
    //     path: '/index/userCenter', name: '', component: () => import('@/views/index/userCenter/layout.vue'),
    //     children: [
    //         { path: '/index/userCenter/index/index', component: () => import('@/views/index/userCenter/index/index.vue'), },
    //         { path: '/index/userCenter/web/list', component: () => import('@/views/index/userCenter/web/list.vue'), },
    //         { path: '/index/userCenter/web/details', component: () => import('@/views/index/userCenter/web/details.vue'), },
    //         { path: '/index/userCenter/order/list', component: () => import('@/views/index/userCenter/order/list.vue'), },
    //         { path: '/index/userCenter/order/details', component: () => import('@/views/index/userCenter/order/orderDetails.vue'), },
    //     ]
    // },


    { path: '/index/userCenter/index/index', component: () => import('@/views/v2/userCenter/index/index.vue'), },
    { path: '/index/userCenter/web/list', component: () => import('@/views/v2/userCenter/web/list.vue'), },
    { path: '/index/userCenter/order/list', component: () => import('@/views/v2/userCenter/order/list.vue'), },
    { path: '/index/userCenter/order/details', component: () => import('@/views/v2/userCenter/order/details.vue'), },


    { path: '/editWeb', name: 'editWeb', component: () => import('@/views/index/editWeb/index.vue') }, // 测试页面
    { path: '*', name: 'page404', component: () => import('@/views/page404.vue') }, // 404页面

]



const router = new VueRouter({
    routes
})


// 全局前置路由守卫
// router.beforeEach((to, from, next) => {
//     // 更换web的title
//     document.title = to.meta.title ? to.meta.title : config.web_name;

//     // 检验是否登录
//     let user_id = localStorage.getItem('user_id')
//     let user_token = localStorage.getItem('user_token')

//     if (user_id && user_token) {
//         next();
//         return;
//     } else {
//         // 登录守护者(可以不用验证登录的页面 必须验证的页面)
//         let notNeedLogins = [
//             'searchIndex',
//             'searchIndex_',
//             'searchDetails',
//             'login',
//             'login_',
//             'register',
//             'register_',
//             'forget',
//             'forget_',
//             'searchIndexV2',
//             'searchIndexV2_',
//         ];
//         console.log(notNeedLogins.indexOf(to.name))
//         if (notNeedLogins.indexOf(to.name) !== -1) {
//             next();
//         } else {
//             next('/login');
//         }
//         return;
//     }
// })


export default router