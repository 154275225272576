<template>
  <el-dialog title="编辑昵称" :visible.sync="dialogVisible" width="400px">
    <el-input v-model="nickname"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="onSubmit" :loadin="loading"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { set_nickname } from "@/api/user.js";
export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      nickname: "",
    };
  },
  methods: {
    start(nickname) {
      this.dialogVisible = true;
      this.nickname = nickname;
    },
    onSubmit() {
      let p = { nickname: this.nickname };
      this.loading = true;
      set_nickname(p)
        .then((res) => {
          if (res.code == 200) {
            this.dialogVisible = false;
            this.$emit("callback", this.nickname);
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style>