<template>
  <div>
    <myCenterLayout :select="'web'">webbb</myCenterLayout>
  </div>
</template>

<script>
import myCenterLayout from "@/components/layout/myCenter/index.vue";
export default {
  components: {
    myCenterLayout,
  },
};
</script>

<style lang="less" scoped>
</style>