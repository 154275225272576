// 更改主题
export const Changetheme = () => {
    document.body.style.setProperty("--xzd_color1", "#0C0B10");
    document.body.style.setProperty("--xzd_color2", "#151417");
    document.body.style.setProperty("--xzd_color3", "#232227");
    document.body.style.setProperty("--xzd_color4", "#21BFA8");
    document.body.style.setProperty("--xzd_color5", "#fff");
    document.body.style.setProperty("--xzd_color6", "#EC7A6B");
    document.body.style.setProperty("--xzd_color7", "#fff");
    document.body.style.setProperty("--xzd_color8", "#aaa");
    document.body.style.setProperty("--xzd_color9", "#151417");
    document.body.style.setProperty("--xzd_color10", "#fff");
    document.querySelector(
        ".user_left"
    ).style.background = `url("http://jianzhan.dev.xinzhidi.cn/upload/20211013/26d00b03d53bc4483bc7e0efeb9a28eb.png") no-repeat`;
    document.querySelectorAll(".right_right_card").forEach((item, i) => {
        if (!(i % 2)) {
            item.style.background =
                "linear-gradient(225deg, rgba(251, 186, 92, 0.85) 0%, rgba(27, 26, 31, 0.19) 51%, #1B1A1F 100%)";
        } else {
            item.style.background =
                "linear-gradient(225deg, #24BCA8 0%, rgba(27, 26, 31, 0.19) 51%, #1B1A1F 100%)";
        }
    });
    document.querySelectorAll('.el-tabs__content').forEach(item => {
        item.style.boxShadow = '4px 6px 16px 0px #000000'
        item.style.background = 'linear-gradient(186deg,rgba(253, 185, 89, 1),rgba(253, 185, 89, 0))'
    })
    document.querySelector('#right').style.boxShadow = '4px 6px 16px 0px #000000'
    document.querySelector('#right').style.background = 'linear-gradient(186deg,rgba(36, 188, 168, 1),rgba(36, 188, 168, 0))'

    document
        .querySelector(".logo")
        .setAttribute(
            "src",
            "http://jianzhan.dev.xinzhidi.cn/upload/20211013/7b2a6f1c4f3f7fd2253521d63c45f57e.png"
        );
}
// 初始化
export const initialTheme = () => {
    document.body.style.setProperty("--xzd_color1", "#615bff");
    document.body.style.setProperty("--xzd_color2", "#f3f5ff");
    document.body.style.setProperty("--xzd_color3", "#ebefff");
    document.body.style.setProperty("--xzd_color4", "#000066");
    document.body.style.setProperty("--xzd_color5", "#333");
    document.body.style.setProperty("--xzd_color6", "#4943ff");
    document.body.style.setProperty("--xzd_color7", "#615BFF");
    document.body.style.setProperty("--xzd_color8", "#333");
    document.body.style.setProperty("--xzd_color9", "#fff");
    document.body.style.setProperty("--xzd_color10", "#fff");

    document.querySelector(
        ".user_left"
    ).style.background = `url("http://jianzhan.dev.xinzhidi.cn/upload/20211013/70ace25039bd4ae205870370cd01e4f4.png") no-repeat`;
    document.querySelectorAll(".right_right_card").forEach((item, i) => {
        item.style.background = "#fff";
    });
    document.querySelectorAll('.el-tabs__content').forEach(item => {
        item.style.boxShadow = '0px 3px 7px 0px rgba(0, 18, 153, 0.2)'
        item.style.background = ''
    })
    document.querySelector('#right').style.boxShadow = '0px 3px 7px 0px rgba(0, 18, 153, 0.2)'
    document.querySelector('#right').style.background = ''

    document
        .querySelector(".logo")
        .setAttribute(
            "src",
            "http://jianzhan.dev.xinzhidi.cn/upload/20211013/15ca993f35468bb9c28f423ac28e744b.png"
        );
}
