<template>
  <div class="header">
    <!-- logo -->
    <img class="logo" src="@/assets/imgs/test/logo_logo.png" alt="logo" />
    <!-- 中间文字 -->
    <p class="center">让每一个企业都拥有属于自己的网站</p>
    <!-- 右侧 -->
    <div class="right">
      <div>
        <el-dropdown>
          <span style="outline: none">
            简体中文
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" style="width: 100px">
            <el-dropdown-item>简体中文</el-dropdown-item>
            <el-dropdown-item>english</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <img class="avatar" src="@/assets/imgs/test/avatar_1.png" alt="avatar" />

      <p>小丸子</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1920px) {
  .header {
    width: 1300px;
    height: 72px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    padding: 0 31px;
    box-sizing: border-box;

    .logo {
      width: 89px;
      height: 30px;
    }
    .center {
      flex: 1;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 700;
      color: var(--xzd_color4);
      margin-left: 60px;
    }

    .right {
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: var(--xzd_color3);
      .el-dropdown {
        color: var(--xzd_color3);
      }
      .avatar {
        width: 29px;
        height: 29px;
        border-radius: 50%;
        margin-left: 32px;
      }
    }
  }
}
@media screen and (min-width: 1920px) {
  .header {
    width: 1920px;
    height: 105px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 4px var(--xzd_color1);
    padding: 0 31px;
    box-sizing: border-box;

    .logo {
      width: 131px;
      height: 48px;
    }
    .center {
      flex: 1;
      text-align: center;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 700;
      color: var(--xzd_color4);
      margin-left: 60px;
    }

    .right {
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      // color: #5f6374;
      color: var(--xzd_color3);
      .el-dropdown {
        color: var(--xzd_color3);
      }
      .avatar {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        margin-left: 32px;
      }
    }
  }
}
</style>