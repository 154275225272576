import axios from 'axios'


import router from '@/router'
import store from '@/store'

import { config as CONFIG } from '@/config/index.js'


import { Notification } from 'element-ui'

let msg = (data) => {
  // window.alert(JSON.stringify(data))
  Notification(data)
}

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'http://127.0.0.1:9024', // url = base url + request url
  // baseURL: 'http://127.0.0.1:9027', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (!('data' in config)) {
      config.data = {}
    }

    if (!('user_id' in config.data)) {
      config.data.user_id = localStorage.getItem('user_id')
    }
    if (!('user_token' in config.data)) {
      config.data.user_token = localStorage.getItem('user_token')
    }

    if (!('language' in config.data)) {
      config.data.language = localStorage.getItem('language')
    }

    config.baseURL = CONFIG.web_host;

    // console.log('请求:' + config.baseURL + config.url, config.data);
    return config
  },
  error => {
    // do something with request error
    msg({
      type: 'error',
      message: error
    })
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    // console.log('响应:'+response.config.baseURL+response.config.url, res);

    if (res.code == 200) {
      return res;
    } else if (res.code == 201) {
      msg({
        type: 'error',
        message: res.msg
      })
      return Promise.reject({ code: 201, msg: res.msg })
    } else if (res.code == 202) {
      msg({
        type: 'error',
        message: res.msg
      })

      store.commit('logout')

      let old = router.history.current.fullPath
      if (old) {
        router.push('/login?old=' + old)
      } else {
        router.push('/login')
      }
      return Promise.reject({ code: 202, msg: res.msg })
    } else {
      return Promise.reject('res.code 尚未定义错误')
    }

  },
  error => {
    msg({
      type: 'error',
      message: error
    })
    return Promise.reject(error)
  }
)

export default service
