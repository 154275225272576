<template>
  <div class="moshi" @click="goWebsite">
    <div class="font1">{{ moshiData.num }}</div>
    <div class="logo"><img class="img" :src="moshiData.imgUrl" alt="" /></div>
    <div class="font2">{{ moshiData.title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    moshiData: {
      type: Object,
    },
  },
  methods: {
    goWebsite() {
      this.$emit("goWebsite");
    },
  },
};
</script>
<style lang="less">
.moshi {
  width: 300px;
  height: 362px;
  border: 4px solid #365aff;
  background: #1735b678;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  color: #a2adf4;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .font1 {
    height: 100px;
    line-height: 100px;
    font-size: 48px;
  }
  .logo {
    .img {
      width: 100px;
      height: 100px;
    }
  }

  .font2 {
    height: 100px;
    line-height: 80px;
    font-size: 22px;
  }
  color: #fff;

}
.moshi:hover {
  color: #fff;
}
.moshi:hover .logo {
  background: url("~@/assets/imgs/indexIndexIndex/bg-img2.png") no-repeat;
  background-size: 100%;
}
</style>