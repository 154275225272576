<template>
  <div>
    <div
      @click="$router.push(item.path)"
      class="test_left_nav"
      :class="'skin_theme_' + skin_theme"
      v-for="(item, i) in leftData"
      :key="item.title"
    >
      <div
        class="icon_box"
        :class="
          (i == 0 && select == 'user') ||
          (i == 1 && select == 'web') ||
          (i == 2 && select == 'order')
            ? 'active'
            : ''
        "
      >
        <img class="icon" :src="item.imgUrl" alt="" />
      </div>
      <span>
        {{ $lang("userV2_left_nav"[i]) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    select: {
      type: String,
      default: "user",
    },
  },
  data() {
    return {
      leftData: [
        {
          imgUrl: require("@/views/test/yyq/test_images/user.png"),
          title: "个人中心",
          path: "/index/userCenter/index/index",
        },
        {
          imgUrl: require("@/views/test/yyq/test_images/web.png"),
          title: "我的网站",
          path: "/index/userCenter/web/list",
        },
        {
          imgUrl: require("@/views/test/yyq/test_images/order.png"),
          title: "我的订单",
          path: "/index/userCenter/order/list",
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
    skin_theme() {
      return this.$store.getters.skin_theme;
    },
  },
};
</script>

<style lang="less" scoped>
.test_left_nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
  cursor: pointer;
  height: 140px;
  .active {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 102, 0.3);
    border-radius: 12px;
  }
  .icon_box {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    .icon {
      width: 28px;
      height: 28px;
    }
    &:hover {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 102, 0.3);
      border-radius: 12px;
    }
  }
}

.skin_theme_one.test_left_nav {
  .active {
    background-color: #4943ff;
  }
  .icon_box:hover {
    background-color: #4943ff;
  }
}

.skin_theme_two.test_left_nav {
  .active {
    background-color: #21bfa8;
  }
  .icon_box:hover {
    background-color: #21bfa8;
  }
}
</style>