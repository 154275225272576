<template>
  <div class="userInfoPagea" :class="'skin_theme_' + skin_theme">
    <div class="userInfoDropdown" v-if="user_id">
      <el-dropdown>
        <div class="userInfo">
          <img class="userLogo" v-if="!head_img" src="./userLogo.png" />
          <img class="userLogo" v-if="head_img" :src="head_img" />
          <span class="userName"> {{ nickname ? nickname : "暂无昵称" }}</span>
        </div>
        <el-dropdown-menu slot="dropdown" style="width: 110px">
          <el-dropdown-item @click.native="goCenter">
            {{ $lang("go_user_center") }}
          </el-dropdown-item>
          <el-dropdown-item @click.native="logout">
            {{ $lang("go_logout") }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="userInfo" v-if="!user_id">
      <img class="userLogo" src="./userLogo.png" alt="" />
      <span @click="login" class="userName"> {{ $lang("go_login") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    user_id() {
      //   return 0;
      return this.$store.getters.user_id;
    },
    nickname() {
      return this.$store.getters.nickname;
    },
    head_img() {
      return this.$store.getters.head_img;
    },
    skin_theme() {
      return this.$store.getters.skin_theme;
    },
  },
  methods: {
    login() {
      this.$store.commit("logout");
      this.$router.push("/login");
    },
    logout() {
      this.$store.commit("logout");
    },
    goCenter() {
      this.$router.push("/index/userCenter/index/index");
    },
  },
};
</script>

<style lang="less" scoped>
.userInfoPagea {
  .userInfoDropdown {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .userInfo {
    margin-left: 20px;
    display: flex;
    align-items: center;

    .userLogo {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      overflow: hidden;
    }
    .userName {
      margin-left: 5px;
      font-size: 12px;
    }
  }
}

.skin_theme_one {
  .userName {
    color: #333;
  }
}
.skin_theme_two {
  .userName {
    color: #fff;
  }
}
</style>