<template>
  <div class="userNameDiv">
    <el-dropdown>
      <span class="el-dropdown-link">
        <slot></slot>
      </span>
      <el-dropdown-menu slot="dropdown" style="width:100px">
        <el-dropdown-item @click.native="goCenter">个人中心</el-dropdown-item>
        <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    nickname: {
      type: String,
      default: "",
    },
  },
  methods: {
    logout() {
      console.log("logout");
      this.$store.commit("logout");
    },
    goCenter() {
      this.$router.push('/index/userCenter/index/index');
    },
  },
};
</script>

<style lang="less" scoped>
.userNameDiv {
  margin-right: 10px;
  cursor: pointer;
}
</style>