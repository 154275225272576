<template>
  <div>
    <moban>
      <div class="div3">
        <moshi
          :moshiData="item"
          v-for="(item, i) in moshiData"
          :key="item.num"
          @goWebsite="goWebsite(i)"
        />
      </div>
    </moban>
  </div>
</template>

<script>
import moshi from "@/components/indexIndexIndex/moshi.vue";

import moban from "@/components/indexIndexIndex/moban.vue";

import languagePackage from "@/languagePackage/index.js";
export default {
  components: {
    moban,
    moshi,
  },
  data() {
    return {
      languagePackage,
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
    moshiData() {
      let ji_jian_jian_zhan = languagePackage[this.language].ji_jian_jian_zhan;
      let shengji_jian_jian_zhan =
        languagePackage[this.language].shengji_jian_jian_zhan;
      let ai_jian_jian_zhan = languagePackage[this.language].ai_jian_jian_zhan;
      let data = [
        {
          num: "01",
          imgUrl: require("@/assets/imgs/indexIndexIndex/index-img1.png"),
          title: ji_jian_jian_zhan,
        },
        {
          num: "02",
          imgUrl: require("@/assets/imgs/indexIndexIndex/index-img2.png"),
          title: ai_jian_jian_zhan,
        },
        {
          num: "03",
          imgUrl: require("@/assets/imgs/indexIndexIndex/index-img3.png"),
          title: shengji_jian_jian_zhan,
        },
      ];
      return data;
    },
  },
  methods: {
    goWebsite(i) {
      switch (i) {
        case 0:
          //   this.$router.push("/index/search/index");
          this.$router.push("/index/search/list");
          break;
        case 1:
          this.$notify({
            type: "error",
            message: "去AI智能建站失败",
          });
          break;
        case 2:
          this.$notify({
            type: "error",
            message: "去升级建站失败",
          });
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.div3 {
  width: 1100px;
  height: 368px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>