import store from '@/store/index.js'

let 简体中文 = {
  web_logo_title: '新知地 建站服务',
  hope: "让每一个企业都拥有属于自己的网站",
  home: '首页',
  change_theme: '更换主题',

  go_login: '去登录',
  go_logout: '退出',
  go_user_center: '个人中心',

  ji_jian_jian_zhan: '极简建站',
  shengji_jian_jian_zhan: '升级建站',
  ai_jian_jian_zhan: 'AI建站',


  loginPage_login_title: '登录',
  loginPage_by_password: '密码登录',
  loginPage_by_code: '短信登录',
  loginPage_phone: '手机号',
  loginPage_password: '密码',
  loginPage_jizhumima: '记住密码',
  loginPage_forget_password: '忘记密码？',
  loginPage_submit: '提交',
  loginPage_register: '注册',
  loginPage_registerV2: '免费注册',

  loginPage_phone_duanxin: '短信验证码',
  loginPage_get_phone_duanxin: '获取验证码',
  loginPage_jizhuwo: '记住我',
  loginPage_success_login: '登录成功',

  forgetPage_title: '忘记密码',
  forgetPage_placeholder_phone: '请输入手机号',
  forgetPage_placeholder_yzm: '请输入验证码',
  forgetPage_get_yzm: '获取验证码',
  forgetPage_placeholder_new_password: '请输入新密码',
  forgetPage_btn_submit: '确定',
  forgetPage_btn_cancel: '取消',

  registerPage_title: '注册',


  searchListPage_mianfei_diy: '免费 DIY',
  searchListPage_web_template_list: '网站模版列表',
  searchListPage_search_industry: '您的行业',
  searchListPage_search_industry_placeholder: '请输入您的行业',
  searchListPage_search_style: '选择风格',
  searchListPage_search_style_search_industry_placeholder: '请输入风格',
  searchListPage_search_btn_str: '搜索',

  searchIndexV2_left_title: '智能搜索',
  searchIndexV2_left_industry: '您的行业',
  searchIndexV2_left_finance_industry: '金融行业',
  searchIndexV2_left_website_purpose: '网站用途',
  searchIndexV2_left_website_type: '网站分类',
  searchIndexV2_left_website_style: '整体风格',
  searchIndexV2_right_wisdom_recommend: '智能推荐',
  searchIndexV2_right_search_again: '再搜一次',

  userV2_no_nickname: '暂无昵称',
  userV2_left_nav: ['个人中心', '我的网站', '我的订单'],
  userV2_right_left_userInfo_title: '个人资料',
  userV2_right_left_userInfo_btn: '更改头像',
  userV2_right_left_userInfo_list_title1: '手机号',
  userV2_right_left_userInfo_list_title2: '用户名',
  userV2_right_left_userInfo_list_title3: '创建时间',
  userV2_right_right_userCenter_title: '个人中心',
  userV2_right_right_userCenter_echarts_title: '浏览统计',
  userV2_right_right_myOrder_title: '我的订单',
  userV2_right_right_myOrder_orderInfo_title: '我的订单-订单详情',
  userV2_right_right_myWebsite_title: '我的网站',
  userV2_right_right_userCenter_btns1: '今日',
  userV2_right_right_userCenter_btns2: '昨日',
  userV2_right_right_userCenter_btns3: '近7天',
  userV2_right_right_userCenter_btns4: '近1月',
  userV2_right_right_userCenter_cardTitle: ['今日访客', '累计访客'],

  userV2_right_right_My_website_search1: '请输入网站名称',
  userV2_right_right_My_website_search2: '还有',
  userV2_right_right_My_website_search3: '几日',
  userV2_right_right_My_website_search4: '到期',
  userV2_right_right_My_website_search_btn: '查询',
  userV2_right_right_My_website_search_list_title1: '网站名',
  userV2_right_right_My_website_search_list_title2: '到期时间',
  userV2_right_right_My_website_search_list_title3: '域名',
  userV2_right_right_My_website_search_list_title4: '操作',
  userV2_right_right_My_website_search_list_title4: '操作',
  userV2_right_right_My_website_search_list_btn1: '编辑',
  userV2_right_right_My_website_search_list_btn2: '设置',
  userV2_right_right_My_website_search_list_btn3: '删除',
  userV2_right_right_My_website_search_list_btn4: '续费',
  userV2_right_right_My_website_search_list_btn5: '浏览数据',
  userV2_right_right_My_website_search_list_btn6: '表单数据',
  userV2_right_right_My_website_search_list_btn7: '用券付费',
  userV2_right_right_My_website_search_list_tips1: '内',
  userV2_right_right_My_website_search_list_tips2: '外',
  userV2_right_right_My_website_search_list_tips3: '尚未设置',

  userV2_right_right_My_order_search1: '请选择订单类型',
  userV2_right_right_My_order_search2: '选择日期',
  userV2_right_right_My_order_search_btn: '查询',
  userV2_right_right_My_order_title1: '订单编号',
  userV2_right_right_My_order_title2: '订单类型',
  userV2_right_right_My_order_title3: '订单金额',
  userV2_right_right_My_order_title4: '下单时间',
  userV2_right_right_My_order_title5: '订单状态',
  userV2_right_right_My_order_title6: '操作',
  userV2_right_right_My_order_btn1: '查看',
  userV2_right_right_My_order_btn2: '删除',
  userV2_right_right_My_order_web_renew: '网站续费',

  userV2_right_right_My_order_details_title: '订单详情',
  userV2_right_right_My_order_details_list_title1: '订单编号',
  userV2_right_right_My_order_details_list_title2: '订单类型',
  userV2_right_right_My_order_details_list_title3: '支付方式',
  userV2_right_right_My_order_details_list_title4: '创建时间',
  userV2_right_right_My_order_details_list_title5: '付款时间',
  userV2_right_right_My_order_details_list_title6: '订单信息',
  userV2_right_right_My_order_details_list_btn1: '继续支付',
  userV2_right_right_My_order_details_list_btn2: '返回',

  dialogxufei_title: '请输入续费天数',
  dialogxufei_date1: '7天',
  dialogxufei_date2: '一个月',
  dialogxufei_date3: '一季',
  dialogxufei_date4: '半年',
  dialogxufei_date5: '一年',
  dialogxufei_btn1: '取消',
  dialogxufei_btn2: '下一步',

  detailsMain_desc1: '网站状态',
  detailsMain_desc1: '网站有效期',
  detailsMain_desc1: '域名管理',
  detailsMain_btn: '查看',

  dialog_form_data_title: '收集的表单',
  dialog_form_data_subtitle1: '编号',
  dialog_form_data_subtitle2: '时间',
  dialog_form_data_subtitle3: '内容',
  dialog_form_data_btn1: '取消',
  dialog_form_data_btn2: '确定',

  dialog_web_data_title: '网站数据',
  dialog_web_data_btn1: '取消',
  dialog_web_data_btn2: '确定',

  everyWebData_title1: '今日浏览',
  everyWebData_title2: '累计浏览',

  editWeb_pc: '电脑',
  editWeb_phone: '手机',
  editWeb_preservation: '保存',
  editWeb_preview: '预览',
  editWeb_free_construction: '免费搭建',

  dialogEditWeb_title: '设置网站基本信息',
  dialogEditWeb_subtitle1: '网站名称',
  dialogEditWeb_nav_icon: '网站导航栏图标',
  dialogEditWeb_subtitle2: '备案号',
  dialogEditWeb_subtitle3: '外部域名',
  dialogEditWeb_subtitle4: '上架',
  dialogEditWeb_subtitle5: 'SEO关键字',
  dialogEditWeb_btn1: '查看教程',
  dialogEditWeb_btn2: '取消',
  dialogEditWeb_btn3: '确定',

  startBuyDialog_title: '免费拥有此网站7日',
  startBuyDialog_subtitle: '请您为网站起一个响亮的名称',
  startBuyDialog_btn1: '取消',
  startBuyDialog_btn2: '确定',

  details_btn: '免费 DIY',
  details_title: '查看主题信息',

}

let 繁体中文 = {
  web_logo_title: '新知地 建站服務',
  hope: "讓每一個企業都擁有屬於自己的網站",
  home: '首頁',
  change_theme: '更換主題',

  go_login: '去登入',
  go_logout: '退出',
  go_user_center: '個人中心',

  ji_jian_jian_zhan: '極簡建站',
  shengji_jian_jian_zhan: '陞級建站',
  ai_jian_jian_zhan: 'AI建站',


  loginPage_login_title: '登入',
  loginPage_by_password: '密碼登入',
  dialogEditWeb_nav_icon: '网站导航栏图标',
  loginPage_by_code: '簡訊登入',
  loginPage_phone: '手機號',
  loginPage_password: '密碼',
  loginPage_jizhumima: '記住密碼',
  loginPage_forget_password: '忘記密碼？',
  loginPage_submit: '提交',
  loginPage_register: '注册',
  loginPage_registerV2: '注册',

  loginPage_phone_duanxin: '簡訊驗證碼',
  loginPage_get_phone_duanxin: '獲取驗證碼',
  loginPage_jizhuwo: '記住我',
  loginPage_success_login: '登入成功',

  forgetPage_title: '忘記密碼',
  forgetPage_placeholder_phone: '請輸入手機號',
  forgetPage_placeholder_yzm: '請輸入驗證碼',
  forgetPage_get_yzm: '獲取驗證碼',
  forgetPage_placeholder_new_password: '請輸入新密碼',
  forgetPage_btn_submit: '確定',
  forgetPage_btn_cancel: '取消',

  registerPage_title: '注册',


  searchListPage_mianfei_diy: '免費 DIY',
  searchListPage_web_template_list: '網站模版清單',
  searchListPage_search_industry: '您的行業',
  searchListPage_search_industry_placeholder: '請輸入您的行業',
  searchListPage_search_style: '選擇風格',
  searchListPage_search_style_search_industry_placeholder: '請輸入風格',
  searchListPage_search_btn_str: '蒐索',

  searchIndexV2_left_title: '智能蒐索',
  searchIndexV2_left_industry: '您的行業',
  searchIndexV2_left_finance_industry: '金融行業',
  searchIndexV2_left_website_purpose: '網站用途',
  searchIndexV2_left_website_type: '網站分類',
  searchIndexV2_left_website_style: '整體風格',
  searchIndexV2_right_wisdom_recommend: '智慧推薦',
  searchIndexV2_right_search_again: '再搜一次',

  userV2_no_nickname: '暫無昵稱',
  userV2_left_nav: ['個人中心', '我的網站', '我的訂單'],
  userV2_right_left_userInfo_title: '個人資料',
  userV2_right_left_userInfo_btn: '更改頭像',
  userV2_right_left_userInfo_list_title1: '手機號',
  userV2_right_left_userInfo_list_title2: '用戶名',
  userV2_right_left_userInfo_list_title3: '創建時間',
  userV2_right_right_userCenter_title: '個人中心',
  userV2_right_right_userCenter_echarts_title: '瀏覽統計',
  userV2_right_right_myOrder_title: '我的訂單',
  userV2_right_right_myOrder_orderInfo_title: '我的訂單-訂單詳情',
  userV2_right_right_myWebsite_title: '我的網站',
  userV2_right_right_userCenter_btns1: '今日',
  userV2_right_right_userCenter_btns2: '昨日',
  userV2_right_right_userCenter_btns3: '近7天',
  userV2_right_right_userCenter_btns4: '近1月',
  userV2_right_right_userCenter_cardTitle: ['今日訪客', '累計訪客'],

  userV2_right_right_My_website_search1: '請輸入網站名稱',
  userV2_right_right_My_website_search2: '還有',
  userV2_right_right_My_website_search3: '幾日',
  userV2_right_right_My_website_search4: '到期',
  userV2_right_right_My_website_search_btn: '査詢',
  userV2_right_right_My_website_search_list_title1: '網站名',
  userV2_right_right_My_website_search_list_title2: '到期時間',
  userV2_right_right_My_website_search_list_title3: '域名',
  userV2_right_right_My_website_search_list_title4: '操作',
  userV2_right_right_My_website_search_list_btn1: '編輯',
  userV2_right_right_My_website_search_list_btn2: '設定',
  userV2_right_right_My_website_search_list_btn3: '删除',
  userV2_right_right_My_website_search_list_btn4: '續費',
  userV2_right_right_My_website_search_list_btn5: '瀏覽數據',
  userV2_right_right_My_website_search_list_btn6: '表單數據',
  userV2_right_right_My_website_search_list_btn7: '用券付費',
  userV2_right_right_My_website_search_list_tips1: '內',
  userV2_right_right_My_website_search_list_tips2: '外',
  userV2_right_right_My_website_search_list_tips3: '尚未設定',

  userV2_right_right_My_order_search1: '請選擇訂單類型',
  userV2_right_right_My_order_search2: '選擇日期',
  userV2_right_right_My_order_search_btn: '査詢',
  userV2_right_right_My_order_title1: '訂單編號',
  userV2_right_right_My_order_title2: '訂單類型',
  userV2_right_right_My_order_title3: '訂單金額',
  userV2_right_right_My_order_title4: '下單時間',
  userV2_right_right_My_order_title5: '訂單狀態',
  userV2_right_right_My_order_title6: '操作',
  userV2_right_right_My_order_btn1: '查看',
  userV2_right_right_My_order_btn2: '删除',
  userV2_right_right_My_order_web_renew: '網站續費',

  userV2_right_right_My_order_details_title: '訂單詳情',
  userV2_right_right_My_order_details_list_title1: '訂單編號',
  userV2_right_right_My_order_details_list_title2: '訂單類型',
  userV2_right_right_My_order_details_list_title3: '支付方式',
  userV2_right_right_My_order_details_list_title4: '創建時間',
  userV2_right_right_My_order_details_list_title5: '付款時間',
  userV2_right_right_My_order_details_list_title6: '訂單資訊',
  userV2_right_right_My_order_details_list_btn1: '繼續支付',
  userV2_right_right_My_order_details_list_btn2: '返回',

  dialogxufei_title: '請輸入續費天數',
  dialogxufei_date1: '7天',
  dialogxufei_date2: '一個月',
  dialogxufei_date3: '一季',
  dialogxufei_date4: '半年',
  dialogxufei_date5: '一年',
  dialogxufei_btn1: '取消',
  dialogxufei_btn2: '下一步',

  detailsMain_desc1: '網站狀態',
  detailsMain_desc1: '網站有效期',
  detailsMain_desc1: '域名管理',
  detailsMain_btn: '查看',

  dialog_form_data_title: '收集的表單',
  dialog_form_data_subtitle1: '編號',
  dialog_form_data_subtitle2: '時間',
  dialog_form_data_subtitle3: '內容',
  dialog_form_data_btn1: '取消',
  dialog_form_data_btn2: '確定',

  dialog_web_data_title: '網站數據',
  dialog_web_data_btn1: '取消',
  dialog_web_data_btn2: '確定',

  everyWebData_title1: '今日流覽',
  everyWebData_title2: '累計瀏覽',

  editWeb_pc: '電腦',
  editWeb_phone: '手機',
  editWeb_preservation: '保存',
  editWeb_preview: '預覽',
  editWeb_free_construction: '免費搭建',

  dialogEditWeb_title: '設定網站基本資訊',
  dialogEditWeb_subtitle1: '網站名稱',
  dialogEditWeb_subtitle2: '備案號',
  dialogEditWeb_subtitle3: '外部域名',
  dialogEditWeb_subtitle4: '上架',
  dialogEditWeb_subtitle5: 'SEO關鍵字',
  dialogEditWeb_btn1: '查看教程',
  dialogEditWeb_btn2: '取消',
  dialogEditWeb_btn3: '確定',

  startBuyDialog_title: '免費擁有此網站7日',
  startBuyDialog_subtitle: '請您為網站起一個響亮的名稱',
  startBuyDialog_btn1: '取消',
  startBuyDialog_btn2: '確定',

  details_btn: '免費 DIY',
  details_title: '查看主題資訊',

}


let english = {
  web_logo_title: 'XZD Establish website service',
  hope: "Let every enterprise have its own website",
  home: 'Home',
  change_theme: 'Change Theme',

  go_login: 'Login',
  go_logout: 'Logout',
  go_user_center: 'My Center',

  ji_jian_jian_zhan: 'Simply build a website',
  dialogEditWeb_nav_icon: 'Site navigation icon',
  shengji_jian_jian_zhan: 'Advanced build a website',
  ai_jian_jian_zhan: 'AI build a website',


  loginPage_login_title: 'Login',
  loginPage_by_password: 'By password',
  loginPage_by_code: 'By phone message',
  loginPage_phone: 'phone',
  loginPage_password: 'password',
  loginPage_jizhumima: 'Remember password',
  loginPage_forget_password: 'Forget password？',
  loginPage_submit: 'Submit',

  loginPage_register: 'Register',
  loginPage_registerV2: 'Register',
  loginPage_phone_duanxin: 'phone message',
  loginPage_get_phone_duanxin: 'get code',
  loginPage_jizhuwo: 'Remember me',
  loginPage_success_login: 'success login',

  forgetPage_title: 'Forget password',
  forgetPage_placeholder_phone: 'Please input phone',
  forgetPage_placeholder_yzm: 'Please input phone message',
  forgetPage_get_yzm: 'get code',
  forgetPage_placeholder_new_password: 'Please input new password',
  forgetPage_btn_submit: 'submit',
  forgetPage_btn_cancel: 'cancel',


  searchListPage_mianfei_diy: 'Free production',
  searchListPage_web_template_list: 'Web template list',
  searchListPage_search_industry: 'Choose industry',
  searchListPage_search_industry_placeholder: 'Please input your industry',
  searchListPage_search_style: 'Choose style',
  searchListPage_search_style_search_industry_placeholder: 'Please select style',
  searchListPage_search_btn_str: 'search',


  searchIndexV2_left_title: 'Wisdom search',
  searchIndexV2_left_industry: 'Your industry',
  searchIndexV2_left_finance_industry: 'Finance',
  searchIndexV2_left_website_purpose: 'Website purpose',
  searchIndexV2_left_website_type: 'Website type',
  searchIndexV2_left_website_style: 'Overall style',
  searchIndexV2_right_wisdom_recommend: 'Wisdom recommend',
  searchIndexV2_right_search_again: 'Search again',

  userV2_no_nickname: 'No nicknaame',
  userV2_left_nav: ['Personal center', 'My website', 'My order'],
  userV2_right_left_userInfo_title: 'Personal data',
  userV2_right_left_userInfo_btn: 'Change avatar',
  userV2_right_left_userInfo_list_title1: 'Phone',
  userV2_right_left_userInfo_list_title2: 'User name',
  userV2_right_left_userInfo_list_title3: 'Created',
  userV2_right_right_userCenter_title: 'Personal center',
  userV2_right_right_userCenter_echarts_title: 'Browse statistics',
  userV2_right_right_myOrder_title: 'My order',
  userV2_right_right_myOrder_orderInfo_title: 'My order-Order info',
  userV2_right_right_myWebsite_title: 'My website',
  userV2_right_right_userCenter_btns1: 'today',
  userV2_right_right_userCenter_btns2: 'Yesterday',
  userV2_right_right_userCenter_btns3: 'Nearly a week',
  userV2_right_right_userCenter_btns4: 'Last month',
  userV2_right_right_userCenter_cardTitle: ['Visitors today', 'Visitors sum'],

  userV2_right_right_My_website_search1: 'Please enter a website name',
  userV2_right_right_My_website_search2: 'Also',
  userV2_right_right_My_website_search3: 'A few days',
  userV2_right_right_My_website_search4: 'Expire',
  userV2_right_right_My_website_search_btn: 'Search',
  userV2_right_right_My_website_search_list_title1: 'Website name',
  userV2_right_right_My_website_search_list_title2: 'Expiration time',
  userV2_right_right_My_website_search_list_title3: 'Domain name',
  userV2_right_right_My_website_search_list_title4: 'Operation',
  userV2_right_right_My_website_search_list_btn1: 'Edit',
  userV2_right_right_My_website_search_list_btn2: 'SetUp',
  userV2_right_right_My_website_search_list_btn3: 'Del',
  userV2_right_right_My_website_search_list_btn4: 'Renew',
  userV2_right_right_My_website_search_list_btn5: 'BrowseData',
  userV2_right_right_My_website_search_list_btn6: 'FormData',
  userV2_right_right_My_website_search_list_btn7: 'Clip Coupon',
  userV2_right_right_My_website_search_list_tips1: 'Within',
  userV2_right_right_My_website_search_list_tips2: 'Outside',
  userV2_right_right_My_website_search_list_tips3: 'Not yet prompted',

  userV2_right_right_My_order_search1: 'Please select order type',
  userV2_right_right_My_order_search2: 'Select date',
  userV2_right_right_My_order_search_btn: 'Search',
  userV2_right_right_My_order_title1: 'Order no',
  userV2_right_right_My_order_title2: 'Order type',
  userV2_right_right_My_order_title3: 'Order amount',
  userV2_right_right_My_order_title4: 'Order time',
  userV2_right_right_My_order_title5: 'Order status',
  userV2_right_right_My_order_title6: 'Operation',
  userV2_right_right_My_order_btn1: 'See',
  userV2_right_right_My_order_btn2: 'Del',
  userV2_right_right_My_order_web_renew: 'Web renew',

  userV2_right_right_My_order_details_title: 'Order details',
  userV2_right_right_My_order_details_list_title1: 'Order no',
  userV2_right_right_My_order_details_list_title2: 'Order type',
  userV2_right_right_My_order_details_list_title3: 'Payment method',
  userV2_right_right_My_order_details_list_title4: 'Creation time',
  userV2_right_right_My_order_details_list_title5: 'Payment time',
  userV2_right_right_My_order_details_list_title6: 'Order info',
  userV2_right_right_My_order_details_list_btn1: 'Continue to pay',
  userV2_right_right_My_order_details_list_btn2: 'Go back',


  dialogxufei_title: 'Please enter renewal days',
  dialogxufei_date1: 'A week',
  dialogxufei_date2: 'One month',
  dialogxufei_date3: 'One quarter',
  dialogxufei_date4: 'Half a year',
  dialogxufei_date5: 'A year',
  dialogxufei_btn1: 'Cancel',
  dialogxufei_btn2: 'Next',

  detailsMain_desc1: 'Site status',
  detailsMain_desc1: 'Website validity',
  detailsMain_desc1: 'Domain name management',
  detailsMain_btn: 'See',

  dialog_form_data_title: 'Collected forms',
  dialog_form_data_subtitle1: 'Number',
  dialog_form_data_subtitle2: 'Time',
  dialog_form_data_subtitle3: 'Content',
  dialog_form_data_btn1: 'Cancel',
  dialog_form_data_btn2: 'Confirm',

  dialog_web_data_title: 'Website data',
  dialog_web_data_btn1: 'Cancel',
  dialog_web_data_btn2: 'Confirm',

  everyWebData_title1: 'Today browse',
  everyWebData_title2: 'Cumulative browsing',

  editWeb_pc: 'Pc',
  editWeb_phone: 'Phone',
  editWeb_preservation: 'Preservation',
  editWeb_preview: 'Preview',
  editWeb_free_construction: 'Free construction',

  dialogEditWeb_title: 'Set basic site information',
  dialogEditWeb_subtitle1: 'Website name',
  dialogEditWeb_subtitle2: 'Record No',
  dialogEditWeb_subtitle3: 'External domain name',
  dialogEditWeb_subtitle4: 'Grounding',
  dialogEditWeb_subtitle5: 'SEO keyword',
  dialogEditWeb_btn1: 'View tutorial',
  dialogEditWeb_btn2: 'Cancel',
  dialogEditWeb_btn3: 'Confirm',

  startBuyDialog_title: 'Have this website for free for seven days',
  startBuyDialog_subtitle: 'Please give the website a loud name',
  startBuyDialog_btn1: 'Cancel',
  startBuyDialog_btn2: 'Confirm',

  details_btn: 'Free DIY',
  details_title: 'View theme info',
}

const l = {
  简体中文,
  english,
  繁体中文
}
export default l

export const lang = (key) => {
  let language = store.getters.language

  if (l[language]) {
    return l[language][key]
  } else {
    return ''
  }
}