<template>
  <div id="app">
    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
        :key="$route.fullPath"
      ></router-view>
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
      :key="$route.fullPath"
    ></router-view>
    <div id="version_str">version : {{ version_str }}</div>
  </div>
</template>

<script>
import { config } from "@/config/index.js";
export default {
  name: "App",
  data() {
    return {
      version_str: config.version_str,
    };
  },
  created() {
    // document.body.removeChild(document.getElementById('loader-wrapper'));
  },
  mounted() {},
  methods: {},
};
</script>

<style>

.el-loading-mask{
    background-color: rgba(255,255,255,.3) !important;
}
#app {
  width: 100%;
  min-width: 1300px;
  /* height: 100%; */
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /* margin-top: 60px; */
  font-size: 14px;
  margin: 0 auto;

  min-height: 100%;
  background: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  /* height: 100%; */
  margin: 0;
  padding: 0;
  /* min-width: 1300px; */
  min-height: 100vh;
  background: #fff;
}

html {
  /* AntD 字体家族：https://ant.design/docs/spec/font-cn#%E5%AD%97%E4%BD%93%E5%AE%B6%E6%97%8F */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

p {
  margin: 0;
  padding: 0;
}

input,
button {
  border: none; /* 去掉浏览器默认的 input 边框样式 */
  outline: none; /* 去掉浏览器默认的聚焦时候的蓝色边框 */
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* 版心 */
.contaniner {
  width: 1300px;
  margin: 0 auto;
}

/* 滚动条美化css */
/* ::-webkit-scrollbar {
  width: 8px; 
  height: 8px; 
  background-color: #fff;
} */

/*定义滚动条轨道 内阴影*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: white;
}

/*定义滑块 内阴影*/
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
</style>

<style lang="less" scoped>
#version_str {
  position: fixed;
  right: 0;
  bottom: 0;
  color: #999;
  font-size: 12px;
  padding: 5px;
}
</style>
