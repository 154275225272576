<template>
  <div class="myCenter" :class="'skin_theme_' + skin_theme">
    <div class="bubai-left"></div>
    <div class="centerDiv">
      <div class="centerDiv-left">
        <leftNav :select="select" />
      </div>
      <div class="centerDiv-right">
        <div class="centerDiv-right-main">
          <div class="main-header">
            <headerV2 />
          </div>
          <div class="main-body">
            <div class="main-body-left">
              <div class="main-body-left-main">
                <userInfo />
              </div>
            </div>
            <div class="main-body-right">
              <div class="bodyNav">
                <div class="dian"></div>
                <span>{{ pageTitle }}</span>
              </div>

              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bubai-right">
      <div class="butai-right-top"></div>
      <div class="butai-right-bottom"></div>
    </div>
  </div>
</template>

<script>
import headerV2 from "@/components/common/headerV2/index.vue";
import leftNav from "@/components/layout/myCenter/c/leftNav.vue";
import userInfo from "@/components/layout/myCenter/c/userInfo.vue";

import { Changetheme, initialTheme } from "@/utils/userTheme";
export default {
  components: {
    headerV2,
    leftNav,
    userInfo,
  },
  props: {
    select: {
      type: String,
      default: "user",
    },
    pageTitle: {
      type: String,
      default: "个人中心",
    },
  },
  data() {
    return {
      // 左侧数据

      // 主题变更
      isChangeTheme: false,
    };
  },
  mounted() {
    // 初始化;
    // let theme = JSON.parse(window.localStorage.getItem("theme"));
    // if (!theme) {
    //   initialTheme();
    //   return;
    // } else {
    //   if (theme === "blankTheme") {
    //     this.isChangeTheme = true;
    //     Changetheme();
    //     return;
    //   }
    //   if (theme === "whiteTheme") {
    //     initialTheme();
    //     return;
    //   }
    // }
  },
  methods: {
    // 换肤按钮
    changeColor() {
      if (this.skin_theme == "one") {
        this.$store.commit("set_skin_theme", "two");
      } else if (this.skin_theme == "two") {
        this.$store.commit("set_skin_theme", "one");
      }
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
    skin_theme() {
      return this.$store.getters.skin_theme;
    },
  },
};
</script>

<style lang="less" scoped>
.myCenter {
  width: 100vw;
  min-width: 1300px;
  height: 100vh;
  display: flex;
  .bubai-left {
    flex: 1;
    height: 100vh;
  }

  .centerDiv {
    width: 1300px;
    height: 100vh;
    display: flex;

    .centerDiv-left {
      width: 121px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    .centerDiv-right {
      width: 1179px;
      height: 100%;
      .centerDiv-right-main {
        width: 100%;
        border-radius: 26px 0px 0px 26px;
        overflow: hidden;
        height: 100%;
        .main-header {
          width: 100%;

          padding-left: 20px;
          height: 71px;
          // background: #f3f7ff;
          line-height: 71px;
          .header {
            width: 100%;
            padding-left: 25px;
          }
        }
        .main-body {
          width: 1179px;
          height: calc(100vh - 71px);
          display: flex;
          .main-body-left {
            width: 264px;
            height: calc(100vh - 71px);
            position: relative;
            .main-body-left-main {
              position: absolute;
              top: 10px;
              left: 25px;
              right: 25px;
              bottom: 20px;
              font-size: 12px;
            }
          }
          .main-body-right {
            width: 915px;
            height: calc(100vh - 71px);
            border-radius: 20px 0px 0px 0px;
          }
        }
      }
    }
  }
  .bubai-right {
    flex: 1;
    height: 100vh;
    .butai-right-top {
      height: 71px;
    }
    .butai-right-bottom {
      height: calc(100vh - 71px);
    }
  }
}

.bodyNav {
  height: 62px;
  padding-left: 25px;
  display: flex;
  align-items: center;

  .dian {
    width: 6px;
    height: 6px;
    border-radius: 4px;
    background: #ff7e28;
    margin-right: 8px;
  }
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
}

.skin_theme_one.myCenter {
  background-color: #f3f5ff;
  .centerDiv {
    .centerDiv-left {
      background-color: #615bff;

      .centerDiv-right-main {
        background-color: #fff;
      }
    }

    .centerDiv-right {
      background-color: #f3f7ff;
      .main-body-right {
        background-color: #ebefff;
      }
    }
  }
  .bodyNav {
    color: #333;
  }

  .bubai-right {
    .butai-right-top {
      background: #f3f7ff;
    }
    .butai-right-bottom {
      background: #ebefff;
    }
  }
}
.skin_theme_two.myCenter {
  background-color: #151417;
  .centerDiv {
    .centerDiv-left {
      background-color: #0c0b10;

      .centerDiv-right-main {
        background-color: #fff;
      }
    }

    .centerDiv-right {
      background-color: #151417;

      .main-body-right {
        background-color: #232227;
      }
    }
  }

  .bodyNav {
    color: #fff;
  }

  .bubai-right {
    .butai-right-top {
      background: #151417;
    }
    .butai-right-bottom {
      background: #232227;
    }
  }
}
</style>