<template>
  <div class="user_box">
    <div class="user_center">
      <!-- 左侧 -->
      <div class="user_center_left">
        <leftCard> </leftCard>
        <el-button class="change_theme" @click="changeColor">
          {{ languagePackage[language].change_theme }}
        </el-button>
      </div>
      <!-- 右侧 -->
      <div class="user_center_right">
        <!-- 头 -->
        <div class="user_header">
          <headerV2></headerV2>
        </div>
        <!-- 身 -->
        <div class="main">
          <!-- 左侧 -->
          <div class="left">
            <rightUserLeft></rightUserLeft>
          </div>
          <!-- 右侧 -->
          <div class="right">
            <div class="right_title">
              <div class="dot"></div>
              <span>
                {{
                  languagePackage[language].userV2_right_right_userCenter_title
                }}
              </span>
            </div>
            <!-- 右上 -->
            <rightRightTop></rightRightTop>
            <!-- 右下 -->
            <rightRightBottom></rightRightBottom>
            <!-- 补白 -->
            <div class="filler"></div>
          </div>
        </div>
      </div>

      <div class="right_blank"></div>
    </div>
  </div>
</template>

<script>
import headerV2 from "@/views/index/search/V2c/headerV2.vue";
import leftCard from "./userCenter_c/leftCard.vue";
import rightUserLeft from "./userCenter_c/rightUserLeft.vue";
import rightRightTop from "./userCenter_c/rightRightTop.vue";
import rightRightBottom from "./userCenter_c/rightRightBottom.vue";
import { Changetheme, initialTheme } from "@/utils/userTheme";
import languagePackage from "@/languagePackage/index.js";

export default {
  components: {
    headerV2,
    leftCard,
    rightUserLeft,
    rightRightTop,
    rightRightBottom,
  },
  data() {
    return {
      languagePackage,
      // 左侧数据
      leftData: [
        {
          icon: "el-icon-user-solid",
          title: "个人中心",
        },
        {
          icon: "el-icon-s-platform",
          title: "我的网站",
        },
        {
          icon: "el-icon-s-order",
          title: "我的订单",
        },
      ],
      // 主题变更
      isChangeTheme: false,
    };
  },
  mounted() {
    // 初始化;
    let theme = JSON.parse(window.localStorage.getItem("theme"));
    if (!theme) {
      initialTheme();
      return;
    } else {
      if (theme === "blankTheme") {
        this.isChangeTheme = true;
        Changetheme();
        return;
      }
      if (theme === "whiteTheme") {
        initialTheme();
        return;
      }
    }
  },
  methods: {
    // 换肤按钮
    changeColor() {
      this.isChangeTheme = !this.isChangeTheme;
      if (this.isChangeTheme) {
        // 防止后续传入的不是字符串
        window.localStorage.setItem("theme", JSON.stringify("blankTheme"));
        // 暗黑主题
        Changetheme();
      } else {
        window.localStorage.setItem("theme", JSON.stringify("whiteTheme"));
        // 光明主题
        initialTheme();
      }
      this.$bus.$emit("curTheme", this.isChangeTheme);
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1920px) {
  .user_box {
    background-color: var(--xzd_color9);
    .user_center {
      width: 1300px;
      height: 100vh;
      margin: 0 auto;
      display: flex;
      background-color: var(--xzd_color1);

      // 左侧
      .user_center_left {
        width: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        .change_theme {
          position: absolute;
          top: 26px;
          left: 30%;
          background-color: var(--xzd_color1);
          border-color: var(--xzd_color4);
          color: var(--xzd_color4);
          height: 22px;
          font-size: 12px;
          padding: 3px;
        }
        .change_theme:hover {
          color: #409eff;
          border-color: #409eff;
        }
      }
      // 右侧
      .user_center_right {
        flex: 1;
        border-top-left-radius: 27px;
        border-bottom-left-radius: 27px;
        background-color: var(--xzd_color2);
        overflow: hidden;
        padding: 0 0 20px 25px;
        .user_header {
          /deep/.header {
            width: 100%;
            box-shadow: 0px 0px 0px;
            padding-right: 10px;
            .center {
              color: var(--xzd_color7);
            }
            .right {
              .el-dropdown,
              .userName {
                color: var(--xzd_color5);
              }
            }
          }
        }
        // 身体
        .main {
          display: flex;
          width: 1179px;
          height: 90%;
          padding: 0 0 0 8px;

          // 左侧
          .left {
            width: 212px;
            height: 100%;
            margin-right: 26px;
          }

          // 右侧
          .right {
            flex: 1;
            background-color: var(--xzd_color3);
            border-top-left-radius: 20px;
            padding: 23px 25px 26px;
            overflow-y: scroll;
            position: relative;
            z-index: 1111;
            .right_title {
              display: flex;
              align-items: center;
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: var(--xzd_color5);
              line-height: 17px;
              .dot {
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: #ff7e28;
                margin-right: 6px;
              }
            }
          }
        }
      }
      .right_blank {
        display: block;
        width: calc(100vw - 1300px);
        height: calc(100vh - 112px);
        background-color: var(--xzd_color3);
        position: absolute;
        right: 0;
        top: 72px;
      }
    }
  }
}
@media screen and (min-width: 1920px) {
  .user_box {
    background-color: var(--xzd_color9);
    .user_center {
      width: 1920px;
      height: 100vh;
      margin: 0 auto;
      display: flex;
      background: var(--xzd_color1);

      // 左侧
      .user_center_left {
        width: 178px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        .change_theme {
          position: absolute;
          top: 26px;
          left: 30%;
          background-color: var(--xzd_color1);
          border-color: var(--xzd_color4);
          color: var(--xzd_color4);
          height: 22px;
          font-size: 12px;
          padding: 3px;
        }
        .change_theme:hover {
          color: #409eff;
          border-color: #409eff;
        }
      }
      // 右侧
      .user_center_right {
        flex: 1;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: var(--xzd_color2);
        overflow: hidden;
        padding: 0 0 30px 38px;
        /deep/.header {
          width: 100%;
          box-shadow: 0px 0px 0px;
          .center {
            color: var(--xzd_color7);
          }
          .right {
            color: var(--xzd_color5);
            .el-dropdown {
              color: var(--xzd_color5);
            }
          }
        }
        // 身体
        .main {
          display: flex;
          width: 1712px;
          height: 930px;
          padding: 0 0 0 8px;

          // 左侧
          .left {
            width: 314px;
            height: 100%;
            margin-right: 38px;
          }

          // 右侧
          .right {
            flex: 1;
            height: 975px;
            background-color: var(--xzd_color3);
            border-top-left-radius: 20px;
            padding: 34px 30px 38px;
            overflow-y: scroll;
            position: relative;
            z-index: 1111;
            .right_title {
              display: flex;
              align-items: center;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: var(--xzd_color5);
              line-height: 25px;
              .dot {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #ff7e28;
                margin-right: 8px;
              }
            }
          }
        }
      }

      .right_blank {
        display: block;
        width: calc(100vw - 1300px);
        height: calc(100vh - 112px);
        background-color: var(--xzd_color3);
        position: absolute;
        right: 0;
        top: 72px;
      }
    }
  }
}
</style>