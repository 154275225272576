<template>
  <div>
    <div class="test_left_nav" v-for="(item, i) in leftData" :key="item.title">
      <div
        class="icon_box"
        :class="
          (i == 0 && select == 'user') ||
          (i == 1 && select == 'web') ||
          (i == 2 && select == 'order')
            ? 'active'
            : ''
        "
      >
        <img class="icon" :src="item.imgUrl" alt="" />
      </div>
      <span>
        {{ item.title }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    select: {
      type: String,
      default: "user",
    },
  },
  data() {
    return {
      leftData: [
        {
          imgUrl: require("@/views/test/yyq/test_images/user.png"),
          title: "个人中心",
        },
        {
          imgUrl: require("@/views/test/yyq/test_images/web.png"),
          title: "我的网站",
        },
        {
          imgUrl: require("@/views/test/yyq/test_images/order.png"),
          title: "我的订单",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1920px) {
  .test_left_nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
    cursor: pointer;
    height: 140px;
    .active {
      background: #4943ff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 102, 0.3);
      border-radius: 12px;
    }
    .icon_box {
      width: 46px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
      .icon {
        width: 28px;
        height: 28px;
      }
      &:hover {
        background: #4943ff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 102, 0.3);
        border-radius: 12px;
      }
    }
  }
}
@media screen and (min-width: 1920px) {
  .test_left_nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
    margin-bottom: 110px;
    cursor: pointer;
    &:hover {
      color: var(--xzd_color4);
    }
    .icon {
      font-size: 28px;
      margin-bottom: 2px;
    }
  }
}
</style>